$color-white: #FFFFFF;
$color-primary: #FE8F01;
$dark-color: #18191F;
$font-family_1: 'Inter';

.shopping_cart {
	position: absolute;
	right: 0px;
	top: 60px;
	width: 370px;
	height: 541px;
	background: $color-white;
	border: 1px solid rgba(0, 0, 0, 0.07);
	box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	z-index: 3;
	padding: 10px;

	.checkout-btn {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 18px 40px;
		gap: 5px;
		width: 100%;
		height: 45px;
		background: $color-primary;
		border: 1px solid $color-primary;
		border-radius: 10px;
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: $color-white;
	}
	.view-cart-btn {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 18px 40px;
		gap: 10px;
		width: 100%;
		height: 45px;
		border: 1px solid $color-primary;
		border-radius: 10px;
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: $color-primary;
	}
}
.cart-summary {
	.sub-total-text {
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 29px;
		color: $dark-color;
	}
	.sub-total-amount {
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 29px;
		color: $color-primary;
	}
}

.cart-wrapper{
	display: flex;
	flex-wrap: wrap;
	gap: 0px 10px;
	overflow-x: hidden;
	@include desktop{
		display: flex;
		flex-wrap: nowrap;
		gap: 0px 10px;
		overflow-x: hidden;
	}
	.product-details{
		display: flex;
		gap: 15px;
		width: 100%;
		border:none;
		@include desktop{
		display: flex;
		gap: 15px;
		width: 60%;
		border-right: 1px solid rgba(0, 0, 0, 0.2);
		}
	}
	.product-summary{
		width: 100%;
		padding: 0px 10px;
		@include desktop{
		width: 70%;
		padding: 0px 10px;
		}
	}
	
	.cart-summary-action{
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		flex-wrap: wrap;

		@include desktop {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		flex-wrap: wrap;
		}
		
	}
	.quantity-control{
		margin-top: 1rem;
		display: flex;
		gap:15px;
		align-items: center;
		border: 1px solid rgba(24, 25, 31, 0.35);
		border-radius: 10px;
		width: max-content;
		padding: 4px 8px;
		@include desktop{
			margin-top: 1rem;
		display: flex;
		gap:15px;
		align-items: center;
		border: 1px solid rgba(24, 25, 31, 0.35);
		border-radius: 10px;
		width: max-content;
		padding: 4px 8px;
		}
	}
	.product-description{
		.product-name{
			font-weight: 600;
			font-size: 18px;
			line-height: 26px;
			color: #18191F;
		}
		.quantity{
			font-weight: 600;
			font-size: 16px;
			line-height: 19px;
		}
		.product-price{
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			margin: .5rem auto;
		}
	}
	.image__wrapper{
		max-width: 120px;
		max-height: 168px;
		
	}
	.product__image{
		width: 100%;
		max-width: 120px;
		height: 100%;
		max-height: 168px;
		object-fit: cover;
		
	}
}
.shopping_cart_item{
	.image__wrapper{
		max-width: 71px;
		max-height: 80px;
		
	}
	.product__image{
		width: 100%;
		max-width: 71px;
		height: 100%;
		max-height: 80px;
		object-fit: cover;
		
	}
}

