.invoice {
  .flex-content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    & .flex-content_child {
      width: 70%;
      display: flex;
      /* Create a custom radio button */
      & .radio-checkmark {
        position: absolute;
        top: 20px;
        left: 10px;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border-radius: 50%;
        @include desktop {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background-color: #eee;
          border-radius: 50%;
        }
      }

      /* On mouse-over, add a grey background color */
      & .radio-container:hover input ~ .radio-checkmark {
        background-color: #ccc;
      }

      /* When the radio button is checked, add a blue background */
      & .radio-container input:checked ~ .radio-checkmark {
        background-color: #ff721b;
      }

      /* Create the indicator (the dot/circle - hidden when not checked) */
      & .radio-checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the indicator (dot/circle) when checked */
      & .radio-container input:checked ~ .radio-checkmark:after {
        display: block;
      }

      /* Style the indicator (dot/circle) */
      & .radio-container .radio-checkmark:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
    }}
  & .invoice-card {
    @include desktop {
      margin-top: 20px;
      background-color: #fff;
      border-radius: 5px;
    }

    & .invoice-header {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-wrap: wrap;
      background-color: $orange;
      @include desktop {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        padding: 20px 30px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-color: $orange;
      }
      .pepperest-logo {
        width: 200px;
        object-fit: contain;
      }
      .details-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        @include desktop {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .invoice-userdetails {
          color: #fff;
          margin-top: 20px;
          @include desktop {
            margin-top: 0;
          }
        }
        .invoice-address {
          color: #fff;
          margin: 10px 0;
          @include desktop {
            margin: 0 0;
          }
        }
      }
    }
    & .invoice-subcontent {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 20px 20px;
      @include desktop {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: 20px 20px;
      }
      .subcontent-address {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include desktop {
          width: 60%;
          display: flex;
          justify-content: space-between;
          flex-direction: row;
        }
        .invoice-number > p:nth-child(3) {
          margin-top: 20px;
        }

      }
      .invoice-total > p:nth-child(2) {
        font-size: 16px;
        color: $dark;
        font-weight: 700;
      }
    }
    .invoice-content {
      margin: 20px 30px;
      border-top: 3px solid $dark;

      .item-header {
        display: flex;
        justify-content: space-between;
        font-weight: 800;
        color: $dark;
        padding-top: 20px;
        font-size: 12px;
        @include desktop {
          font-size: 16px;
        }
        .item-unit {
          width: 60%;
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          @include desktop {
            font-size: 16px;
          }
        }
      }
      .item-container {
        padding: 2px 0;
        .item-content {
          display: flex;
          justify-content: space-between;
          padding: 20px 0 10px 0;
          border-bottom: 1px solid $silver;
          font-size: 12px;
          @include desktop {
            font-size: 16px;
          }
          .item-content-description > p:nth-child(2) {
            font-size: 13px;
            color: $gray;
          }
          .item-content-children {
            width: 60%;
            display: flex;
            justify-content: space-between;
            .item-quantity {
              margin-left: 1px;
              @include desktop {
                margin-left: 20px;
              }
            }
            .item-cost {
              margin-left: 1px;
              @include desktop {
                margin-left: 20px;
              }
            }
            .item-amount {
              margin-right: 3px;
            }
          }
        }
      }
      .total-items {
        display: flex;
        justify-content: flex-end;
        .total-items-container {
          width: 100%;
          padding: 20px 0;
          @include desktop {
            width: 28%;
            padding: 20px 0;
          }
          .total-items-content {
            display: flex;
            justify-content: space-between;
            padding: 6px 0;
          }
          .total-items-content > p:nth-child(1) {
            font-weight: 800;
          }
        }
      }
    }
    .invoice-footer {
      padding: 30px;
      .invoice-footer-child {
        display: flex;
        justify-content: flex-start;
      }
    }
    .grey-format {
      color: $gray;
      font-size: 13px;
      margin-bottom: 6px;
    }
  }
}

//invoices
.invoices{
  margin-top: 2rem;
  .title__wrapper{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .page__title{
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          color: #495057;
      }
  }
  .invoice__action{
      display: grid;
      grid-template-columns:  1fr 1fr;
      justify-content: space-around;
      align-items: center;
      border:1px solid $purple;
      border-radius: 10px;
      .edit__action{
          border-right: 1px solid $purple;
          padding: 12px 15px;
          cursor: pointer;
      }
      .delete__action{
          padding: 12px 15px;
          cursor: pointer;
      }
  }
  .invoice__types{
    display: flex;
    gap: 1.375rem;
    align-items: center;
    padding: 1.875rem 0rem;
    .type{
      color: rgba(73, 80, 87, 0.55);
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      &.active__type{
        color: #495057;
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
  .invoice__type__actions{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap:.8rem;
    .upload__proof__button{
      border: 1px solid $purple;
      background: white;
      border-radius: 8px;
      color: $purple;
      outline: none;
      cursor: pointer;
      width: 100%;
      padding: 10px 12px;
      text-wrap: nowrap;
    }
    .request__payment__button{
      color: white;
      border-radius: 8px;
      background: $purple;
      border: none;
      outline: none;
      cursor: pointer;
      width: 100%;
      padding: 10px 12px;
      text-wrap: nowrap;
      &:disabled{
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }
  
}
