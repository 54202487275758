$color_1: #FFFFFF;
$font-family: 'Inter';

.deals {
    font-family: $font-family;
    margin-top: 3rem;
    @include desktop{
        margin-top: auto;
    }
  
	.title {
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        color: $color_1;

		@include desktop{
       
		font-style: normal;
		font-weight: 800;
		font-size: 28px;
		line-height: 34px;
		color: $color_1;
        }
	}
	.text {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: $color_1;

		@include desktop{
        font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: $color_1;
        }
	}
	.more {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        width: max-content;

		@include desktop{
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: $color_1;
            cursor: pointer;
            text-decoration: none;
        }
	}
}
