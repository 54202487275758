.homepage {
  background-color: $whitesmoke;
  scroll-behavior: smooth;
  /*Navbar style*/
  .nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    min-height: 4.375rem;
    align-items: center;
    flex-wrap: wrap;
    background-color: #10182a;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }
  .nav-container {
    padding-right: 0;
    padding-left: 0;
  }
  .nav-brand {
    height: 4.375rem;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 2rem;
  }
  .nav-brand > a {
    font-size: 1.5rem;
    text-decoration: none;
    color: #4673e4;
    font-weight: 500;
  }
  .nav-left,
  .nav-right {
    display: block;
  }

  .nav-menu {
    width: 100%;
    display: none;
    padding: 0 6px;
    flex-grow: 1;
  }
  .nav-menu.active {
    display: block;
  }
  .nav-link,
  .nav-item {
    height: 4.375rem;
    display: flex;
    align-items: center;
    padding: 0 0.75rem;
  }
  .nav-mobile {
    width: 100%;
    height: 4.375rem;
    padding: 0 10px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(238, 238, 238, 0.479);
  }
  .nav-menu-button {
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 0.25rem;
    color: #fff;
    background-color: transparent;
    border: none;
  }
  .nav-menu-button:focus {
    outline: none;
  }
  .nav-link,
  .nav-item {
    flex-grow: 1;
  }
  .nav-link {
    color: #fff;
    text-decoration: none;
  }
  .nav-svg {
    margin-right: 5px;
    margin-top: 4px;
  }
  .nav-link:hover {
    color: #ddd;
    background-color: #585a5f42;
    transition: all ease-in-out 300ms;
  }
  .nav-link.active {
    color: #ddd;
  }
  .nav-menu-hamburger {
    width: 1rem;
    height: 1rem;
    fill: currentColor;
  }
  @media (min-width: 1024px) {
    .nav-brand {
      height: 4.375rem;
    }
    .nav > .container {
      width: 100%;
      display: flex;
      align-items: stretch;
    }
    .nav-left,
    .nav-right {
      display: flex;
      align-items: center;
    }
    .nav-right {
      margin-left: auto;
    }
    .nav-left {
      margin-right: auto;
    }
    .nav-menu {
      width: auto;
      display: flex;
      align-items: center;
    }
    .nav-link,
    .nav-item,
    .nav-brand {
      height: 4.375rem;
      display: flex;
      align-items: center;
    }
    .nav-menu-button {
      display: none;
    }
    .nav-mobile {
      width: auto;
      border-bottom: unset;
    }
  }
  @media (min-width: 1280px) {
    .nav > .container-fluid {
      width: 100%;
      display: flex;
      align-items: stretch;
    }
  }

  .hero {
    &-wrapper {
      background-color: $black;
      height: 640px;
    }

    & .icon {
      position: relative;
      left: 20px;
    }

    & .title {
      font-family: $secondary-font !important;
      font-size: $font-size-6;
      font-weight: bold;
      line-height: 1.25;
      color: $white;
      margin-top: 12px;
      margin-bottom: 8px;
    }

    & .info {
      line-height: 1.5;
      font-size: $font-size-3;
      color: $gray;
      margin-top: 8px;
      margin-bottom: 16px;
    }
    .globe-bg {
      position: absolute;
      right: -150px;
    }

    &-actions {
      display: flex;
      flex-direction: row;
      margin-top: 16px;

      & .button {
        margin: 0px 12px;

        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &-details {
      padding-top: 30px;
      @include desktop {
        padding-top: 103px;
        display: flex;
        flex-direction: column;
      }
      &--attention {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 12px;

        &-badge {
          width: 48px;
          height: 24px;
          border-radius: $border-radius-1;
          background-color: #c1f0c0;
          font-family: $medium-font !important;
          font-size: $font-size-1;
          font-weight: bold;
          color: $refund-text-color;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
        }

        &-text {
          color: $white;
          font-size: $font-size-2;
          margin-left: 8px;
        }
      }
    }
  }

  & .section-one {
    height: 1000px;
    background: url(/assets/images/svg/background-1x.svg) no-repeat;
    margin-top: 144px;
    z-index: $z-index-2;
    position: relative;
    background-size: 2000px;

    @include desktop {
      height: 700px;
      background: url(/assets/images/svg/background-1x.svg) no-repeat;
      margin-top: 144px;
      z-index: $z-index-2;
      position: relative;
    }
    .title {
      font-size: $font-size-8;
      font-family: $secondary-font !important;
      line-height: 1.25;
      color: $black;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .info {
      color: $gray;
      font-size: $font-size-small;
      line-height: 1.6;
      margin-top: 12px;
      margin-bottom: 16px;
    }

    &__list {
      margin-top: 16px;

      &-item {
        font-size: $font-size-5;
        color: $black;
        line-height: 1.6;
        font-weight: 500;
        margin: 8px 0px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &.text--orange {
          font-weight: 600;
        }

        & .icon {
          margin-left: 16px;
          animation: fadeIn 0.2s linear 0s forwards;
        }

        &:first-child {
          margin-top: 0px;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    &__image-section {
      width: 100%;

      img {
        width: 300px;
        object-fit: contain;
        margin-top: 40px;
      }
      @include desktop {
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  & .mycard {
    width: 100%;
    border-radius: $border-radius-3;
    box-shadow: 0 2px 15px 0 rgba(231, 231, 231, 0.5); //0 15px 20px 0 rgba(231, 231, 231, 0.5);
    background-color: $white;
    margin-top: 16px;
    margin-bottom: 12px;

    &:first-child {
      margin-bottom: 12px;
    }

    &:last-child {
      margin-top: 12px;

      & .mycard-header {
        border: none;
      }
    }

    &-header {
      padding: 22px 0px;
      margin: 0px 32px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid $border-color-1;

      &__title {
        font-size: $font-size-5;
        color: $orange;
        font-weight: 500;
        font-family: $medium-font !important;
        line-height: 1.33;
        width: 320px;
      }
    }

    &-body {
      padding: 24px 32px 36px 32px;
      background-color: #fcf7f2;
      border-bottom-left-radius: $border-radius-3;
      border-bottom-right-radius: $border-radius-3;

      &-content {
        font-size: $font-size-small;
        width: 100%;
        color: $gray;
        line-height: 1.66;
        @include desktop {
          width: 426px;
        }
      }
    }
  }

  & .section-two {
    width: 100%;
    height: 1520px;
    background: url(/assets/images/svg/background-2x.svg) no-repeat;
    background-size: cover;
    z-index: $z-index-1;
    position: relative;
    padding-top: 80px;
    @include desktop {
      width: 100%;
      height: 925px;
      background: url(/assets/images/svg/background-2x.svg) no-repeat;
      background-size: cover;
      z-index: $z-index-1;
      position: relative;
      padding-top: 160px;
    }
    // top: -145px;

    &__main-content {
      display: flex;
      flex-direction: column;

      & .title {
        font-family: $secondary-font !important;
        font-size: $font-size-8;
        font-weight: bold;
        line-height: 1.25;
        color: $white;
        margin-top: 12px;
        margin-bottom: 8px;
        // width: 458px;
      }

      & .info {
        line-height: 1.5;
        font-size: $font-size-3;
        margin-top: 8px;
        margin-bottom: 16px;
        color: $white;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius-3;

      &-container {
        height: 400px;
        @include desktop {
          height: 584px;
        }
      }

      &-wrapper {
        & .mycard {
          height: 184px;
          width: 80%;
          margin: 0px auto;
          position: relative;
          top: -120px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .item {
            &-wrapper {
              height: 128px;
              width: 128px;
              border-radius: 100%;
              background-color: $white;
              box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.79);
              position: relative;
              top: -64px;
              margin-bottom: -64px;
            }

            &__title {
              color: $gray;
              font-size: $font-size-3;
              margin-bottom: 4px;
              margin-top: 8px;
            }

            &__price {
              color: $black;
              font-size: $font-size-3;
              font-weight: 600;
            }
          }

          &-footer {
            background-color: #e6fff6;
            width: 100%;
            height: 48px;
            border-bottom-left-radius: $border-radius-3;
            border-bottom-right-radius: $border-radius-3;
            margin-top: auto;

            &-content {
              width: 80%;
              margin: 0px auto;
              height: 100%;
              border-top: 1px dashed #0d9967;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              font-weight: 500;
              font-family: $medium-font !important;
              font-size: $font-size-3;
              color: #0d9967;
            }
          }
        }
      }
    }
  }

  & .section-three {
    background: url(/assets/images/svg/background-1x.svg) no-repeat;
    margin-top: 144px;
    padding: 80px 0;
    z-index: 3;
    position: relative;
    margin-top: 0;
    background-size: 1650px;

    @include desktop {
      height: 810px;
      background: url(/assets/images/svg/background-1x.svg) no-repeat;
      margin-top: 144px;
      padding: 80px 0;
      z-index: 3;
      position: relative;
      margin-top: 0;
      background-size: 1500px;
    }

    & .title {
      font-family: $secondary-font !important;
      font-size: $font-size-8;
      font-weight: bold;
      line-height: 1.25;
      color: $black;
      margin-top: 12px;
      margin-bottom: 8px;
      // width: 458px;
    }

    & .info {
      line-height: 1.5;
      font-size: $font-size-3;
      margin-top: 116px;
      margin-bottom: 16px;
      color: $white;
      @include desktop {
        line-height: 1.5;
        font-size: $font-size-3;
        margin-top: 8px;
        margin-bottom: 16px;
        color: $white;
      }
    }

    &__content-section {
      // padding-left: 80px;

      & .section-one__list {
        margin-top: 115px;
      }

      &__footer {
        display: flex;
        flex-direction: row;
        margin-top: 48px;

        & .button {
          width: 50%;
          margin: 0px 12px;

          &:first-child {
            margin-left: 0px;
          }

          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }

    &__other-section {
      & .info {
        color: $gray;
        line-height: 1.6;
        font-size: $font-size-small;
      }

      & .image-wrapper {
        display: flex;
        flex-direction: row;
        position: relative;
        margin-top: 80px;

        & .img {
          &:first-child {
            width: 325px;
            height: 400px;

            & img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }

          &:last-child {
            width: 325px;
            height: 300px;
            position: absolute;
            right: 24px;
            top: 50px;
            // left: 50px;
            z-index: -1;
            @include desktop {
              width: 325px;
              height: 300px;
              position: absolute;
              right: 50px;
              top: 50px;
              // left: 50px;
              z-index: -1;
            }

            & img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  & .section-four {
    width: 100%;

    @include desktop {
      width: 1200px;
      min-height: 925px;
      margin: auto;
      height: auto;
      margin-top: 75px;
      background: url(/assets/images/svg/background-3x.svg) no-repeat;
      background-size: cover;
      padding-bottom: 72px;
    }

    &__title {
      font-size: $font-size-8;
      font-family: $secondary-font !important;
      line-height: 1.25;
      color: $black;
      font-weight: bold;
      width: 325px;
      margin-bottom: 12px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      margin-top: 150px;

      &-item {
        padding: 24px 0px;
        border-bottom: 1px solid $silver;

        &:last-child {
          border: none;
        }

        &-title {
          color: $black;
          font-size: $font-size-5;
          font-family: $medium-font !important;
          font-weight: 500;
          margin-bottom: 8px;
        }

        &-info {
          color: $gray;
          font-size: $font-size-small;
          line-height: 1.6;
          margin-top: 8px;
        }
      }
    }

    &__illustration {
      position: relative;
      margin-top: 56px;
      @include desktop {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: 56px;
      }

      & .icon {
        &:first-child {
          z-index: $z-index-2;
          animation: moveUpAndDown 10s linear 0s infinite forwards;
        }

        &:last-child {
          position: relative;
          left: 25px;
          top: -20px;
        }
      }
    }
  }

  & .section-five {
    margin: 209px 0px 50px 0;

    @include desktop {
      margin: 209px 0px;
    }

    &__title {
      width: 100%;
      margin: 50px 0 20px 0;
      @include desktop {
        color: $orange;
        font-size: $font-size-semi;
        font-family: $medium-font !important;
        font-weight: 500;
        margin-bottom: 8px;
        width: 460px;
        margin-bottom: 12px;
      }
    }

    &__info {
      width: 100%;
      @include desktop {
        color: $gray;
        font-size: $font-size-small;
        line-height: 1.6;
        margin-top: 8px;
        width: 479px;
        margin-top: 12px;
      }
    }

    &__logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 16px;
    }

    & .mycard {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 292px;
      margin: 0;

      &-info {
        width: 171px;
        height: 31.8px;
        border-radius: 15.9px;
        background-color: #b6bac5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: $medium-font !important;
        font-size: $font-size-2;
        font-weight: 500;
        color: white;
        position: relative;
        top: -15px;
        // margin-bottom: 15px;
      }

      &-header {
        display: flex;
        flex-direction: row;
        padding: 0px;
        border: none;

        &__circle {
          border-radius: 50%;
          width: 24px;
          height: 24px;
          background-color: rgb(236, 249, 234);
          margin-right: 8px;
        }

        &__text {
          font-family: $medium-font;
          font-size: $font-size-3;
          color: $black;
          font-weight: 600;
          margin-left: 8px;
        }
      }

      &-body {
        padding: 24px 0px;
        width: 100%;
        border: 1px dashed $border-color-1;
        border-left: 0;
        border-right: 0;
        background-color: $white;
        border-radius: 0px;
        margin: 24px 0px;

        &__text {
          font-size: $font-size-2;
          line-height: 1.43;
          text-align: center;
          width: 279px;
          margin: 0px auto;
          color: $gray;
        }
      }

      &-footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__text {
          color: $orange;
          font-size: $font-size-large;
          font-family: $semibold-font !important;
        }
      }
    }
  }

  &-footer {
    background-color: $white;
    // height: 600px;

    &-container {
      display: flex;
      flex-direction: column;
      height: 600px;
    }

    &__header {
      padding: 40px 0px;
      border-bottom: 1px solid $silver;

      & .title {
        font-family: $secondary-font !important;
        font-size: $font-size-8;
        color: $black;
      }

      & .info {
        margin: 20px 0 30px 0;
        @include desktop {
          font-size: $font-size-small;
          color: $gray;
          margin-top: 8px;
        }
      }

      &-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;

        & .button {
          width: 50%;
          margin: 0px 12px;

          &:first-child {
            margin-left: 0px;
          }

          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }

    &-base {
      border-top: 1px solid $silver;
      padding: 24px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: auto;

      &-text {
        font-size: $font-size-3;
        line-height: 2;
        color: $gray;
        font-weight: 600;
      }
    }

    &-content {
      padding-top: 64px;
    }

    & .footer-list {
      &-item {
        display: block;
        font-size: $font-size-3;
        line-height: 2;
        color: $gray;
        margin: 4px 0px;
        &:hover {
          text-decoration-color: $gray;
        }

        &:first-child {
          font-family: $medium-font !important;
          font-weight: 600;
          margin-top: 0px;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

@keyframes moveUpAndDown {
  0%,
  100% {
    transform: translateY(0px);
  }

  95% {
    transform: translateY(100px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
