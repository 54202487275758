:root {
	--primary-color: #5e60ce;
	--black-color: #495057;
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Nunito", sans-serif;
}

/*
  3. Allow percentage-based heights in the application
*/
/* html,
body {
	height: 100%;
	font-size: 62.5%;
	background-color: #ffffff;
}

@media screen and (max-width: 768px) {
	html {
		font-size: 56.25%;
	}
}

@media screen and (max-width: 456px) {
	html {
		font-size: 50%;
	}
} */

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
}

/*
  6. Improve media defaults
*/
/* img,
picture,
video,
canvas,
svg {
	display: block;
	max-width: 100%;
} */

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
	font: inherit;
}
button:disabled{
	opacity: .7;
    cursor: not-allowed;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
}
/* p{
	margin-top: 0;
    margin-bottom: 1rem;
} */

/*
  9. Create a root stacking context
*/
#root,
#__next {
	isolation: isolate;
}

.app-wrapper {
	min-height: 100vh;
	/* padding: 0 70px 20px; */
	display: flex;
	flex-direction: column;
	
}

.pointy-cursor {
	cursor: pointer;
}

.full-width {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-inline: -50vw;
	z-index: -1;
}

div.accordion {
	width: 100%;
	border: none;
}

div.accordion__button {
	background: #a4a4a4;
	font-style: normal;
	font-weight: 500;
	font-size: 1.6rem;
	line-height: 2.4rem;
	color: white;
}

div.accordion__button::before {
	display: none;
}

.text-danger {
	font-size: 1rem;
	margin-top: 2px;
}

.swal2-popup {
	font-size: 1.6rem !important;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
	input[type="checkbox"],
	input[type="radio"] {
		accent-color: #5e60ce;
	}
}

/* modal */
.modal-header {
	border: none;
}

.modal-header .close {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	background: #dfe1e3;
	width: 40px;
	height: 40px;
	border-radius: 8px;
	font-size: 2.2rem;
}

.modal-xlg {
	width: 1200px !important;
	max-width: 1200px;
}

.modal-xsm {
	width: 200px !important;
	max-width: 200px;
}

.pagination {
	-webkit-box-pack: center;
	justify-content: center;
}

.pagination .page-item .page-link {
	display: inline-block;
	width: 40px;
	height: 40px;
	border-radius: 0;
	border: 1px solid #dedede;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	margin-right: 6px;
	color: #757575;
}

.pagination .page-item .page-link:hover {
	background: #5e60ce;
	color: #fff;
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
	border-radius: 0;
}

.pagination .page-item.active .page-link {
	background: #5e60ce;
	color: #fff;
	border-color: #5e60ce;
}

@media screen and (max-width: 1024px) {
}
