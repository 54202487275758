.list {
  width: 100%;

  &-header {
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: $border-radius-2;
    border-top-right-radius: $border-radius-2;
    background-color: $lavender;
    padding: 12px 16px;
    box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
    @include desktop {
      padding: 12px 24px;
    }

    .nsForm-input {
      width: 200px;
      background-color: $white;
      height: 32px;
      margin: 0px;
      font-size: $font-size-1;

      @include desktop {
        width: 400px;
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
    }

    &__action {
      width: 32px;
      height: 32px;
      border-radius: $border-radius-2;
      background-color: rgb(209, 211, 219);
      margin: 0px 8px;
      padding: 0px 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      position: relative;

      @include desktop {
        &:hover {
          background-color: $white;
        }
        &.active {
          background-color: $white;
        }
      }
      @include desktop {
        min-width: 104px;
        width: auto;
        padding: 0px 16px;
      }
      &--alt {
        background-color: $gray;
        &:hover {
          @include desktop {
            background-color: $white;
            & .list-header__action-text--alt {
              color: $gray;
            }
            & .icon {
              path {
                fill: $gray;
                stroke: $gray;
              }
            }
          }
        }
      }

      &-text {
        display: none;
        font-size: $font-size-1;
        @include medium-font;
        color: $light-gray;

        @include desktop {
          display: block;
        }

        &--alt {
          color: $white;
          // &:hover{
          //     color: $gray;
          // }
        }
      }
      &:first-child {
        margin-left: 0px;
        // &:hover{
        //     @include desktop{
        //         & .list-header__action-menu {
        //             display: block;
        //             opacity: 1;
        //         }

        //     }
        // }
      }
      &:last-child {
        margin-right: 0px;

        // &:hover {
        //     @include desktop {
        //         & .list-header__action-menu__alt {
        //             display: block;
        //             opacity: 1;
        //         }

        //     }
        // }
      }

      & .icon {
        @include desktop {
          margin-right: 8px;
        }
      }

      &-menu,
      &-menu__alt {
        position: absolute;
        top: 56px;
        left: 0;
        right: 0;
        width: 264px;
        height: auto;
        z-index: $z-index-1;
        border-radius: $border-radius-1;
        box-shadow: 0 0 30px 0 #e7e7e7;
        background-color: $white;
        padding: 24px;
        display: none;
        opacity: 1;
        transition: all 0.2s ease-in-out;
        @include mobileAndTablet {
          display: none !important;
        }

        &__body {
          display: flex;
          flex-flow: column nowrap;

          & .nsForm-input,
          .nsForm-textarea {
            width: 100%;
            height: 40px;
          }
        }
        &-control {
          margin: 8px 0px;
          &:first-child {
            margin-top: 0px;
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
        &-base {
          display: flex;
          flex-flow: row nowrap;
          margin-top: 8px;

          & .button {
            // width: auto;
            margin: 0px 8px;
            &:first-child {
              margin-left: 0px;
            }

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }

      &-menu {
        &::after {
          content: "";
          width: 24px;
          height: 24px;
          background-color: $white;
          position: absolute;
          top: -12px;
          transform: rotate(45deg);
          left: 24px;
        }
      }

      &-menu__alt {
        left: unset;
        ::after {
          content: "";
          width: 24px;
          height: 24px;
          background-color: $white;
          position: absolute;
          top: -12px;
          transform: rotate(45deg);
          right: 24px;
        }
      }
    }
  }

  &-footer {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    justify-content: center;
    @include desktop {
      justify-content: space-between;
    }

    &-text,
    &-label {
      font-size: $font-size-2;
      color: $light-gray;

      &-alt {
        color: $black;
        // font-weight: 600;
      }
    }

    &-label {
      display: none;
      @include desktop {
        display: flex;
      }
    }

    &__pagination {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-page-number {
        margin: 0px 8px;
      }
      &-next {
        border: 0;
        padding: 0;
        background-color: none;
        margin-right: 24px;
        margin-left: 8px;
        // font-weight: 600;
      }
      &-prev {
        border: 0;
        padding: 0;
        background-color: none;
        margin-left: 24px;
        margin-right: 8px;
        // font-weight: 600;
      }
      &-prev,
      &-next,
      &-page-number {
        cursor: pointer;
      }
    }
  }

  &-body {
    box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
  }

  &-item {
    min-height: 48px;
    height: auto;
    background: $white;
    border: solid 0.5px $border-color-1;
    border-left: 0px;
    border-right: 0px;
    position: relative;
    padding: 8px 16px 8px 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer !important;
    @include desktop {
      height: 48px;
      min-height: 48px;
      align-items: center;
      padding: 8px 24px 8px 24px;
    }
    &:first-child {
      border-top: 0px;
    }
    &:last-child {
      border-bottom: 0px;
      border-bottom-right-radius: $border-radius-1;
      border-bottom-left-radius: $border-radius-1;
    }
    &__alt {
      height: 64px;

      & .list-item__details-product {
        width: auto;
        margin-left: 0px;
        margin-right: 0px;
      }
    }

    &__indicator {
      position: absolute;
      left: 0;
      height: 70%;
      width: 2px;
      top: calc(100% - 85%);
      padding-inline: 2px !important;
      @include desktop {
        top: calc(100% - 85%);
        height: 32px;
      }

      &-active {
        background-color: $refund;
      }
      &-blocked {
        background-color: $closed;
      }
      &-pending {
        background-color: $pending;
      }
      &-awaiting {
        background-color: $awaiting;
      }
      &-open {
        background-color: $open;
      }
      &-fulfilled {
        background-color: $fulfilled;
      }
      &-initial {
        background-color: $initial;
      }
      &-flagged {
        background-color: $flagged;
      }
      &-closed {
        background-color: $closed;
      }
      &-refund {
        background-color: $refund;
      }
      &-release {
        background-color: $released;
      }
      &-stop {
        background-color: $stop;
      }
    }
    &__date {
      &-text {
        opacity: 0.5;
        font-size: $font-size-2;
        color: $black;
      }

      & .text--smaller {
        @include medium-font;
      }
    }
    &__details {
      &-container {
        display: flex;
        flex-direction: column;
        @include desktop {
          flex-direction: row;
          justify-content: center;
          margin: 0px auto;
        }
      }
      &-product {
        font-size: $font-size-2;
        @include medium-font;
        color: $black;
        text-align: left;
        margin-bottom: 4px;
        @include desktop {
          width: 50%;
          margin-left: auto;
          text-align: right;
          margin-right: 12px;
          margin-bottom: 0px;
        }
        &__alt {
          @include desktop {
            width: 30%;
          }
        }

        &__image {
          width: 72px;
          height: 72px;
          object-fit: cover;
          border-radius: $border-radius-1;
          margin: 0px 12px;
          &:first-child {
            margin-left: 0px;
          }
          &:last-child {
            margin-right: 0px;
          }

          &-container {
            display: flex;
            flex-flow: row nowrap;
            overflow-x: scroll;
            width: 100%;

            // padding: 24px 0px;
          }
        }
      }
      &-email {
        opacity: 0.5;
        font-size: $font-size-2;
        color: $black;
        text-align: left;
        margin-top: 4px;

        @include desktop {
          width: 50%;
          margin-right: auto;
          margin-left: 12px;
          margin-top: 0px;
        }

        &__alt {
          @include desktop {
            width: 70%;
          }
        }
      }
      &-product,
      &-email {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include desktop {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &__payment {
      // display: none;
      &-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      & .button {
        display: none;
        @include desktop {
          margin-right: 8px;
          display: block;
        }
      }

      &-price {
        @include medium-font;
        font-size: $font-size-2;
        color: $black;
        margin-left: 8px;
        text-align: right;
        width: 94px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__more {
      display: none;
      @include desktop {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 40px;
        cursor: pointer;
        outline: none;
      }

      & .dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #d8d8d8;
        margin: 0px 4px;
        &:first-child {
          margin-left: 0px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }

    &__status {
      display: none;
      @include desktop {
        margin-left: 24px;
        display: block;
      }
      &-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &-tag {
        padding: 6px 8px;
        width: auto;
        height: 24px;
        border-radius: $border-radius-2;
        font-size: $font-size-1;
        @include medium-font;
        display: flex;
        flex-direction: row;
        align-items: center;
        &--active {
          background-color: $refund-tag;
        }
        &--blocked {
          background-color: $closed-tag;
        }
        &--pending {
          background-color: $pending-tag;
        }
        &--initial {
          background-color: $initial-tag;
        }
        &--open {
          background-color: $open-tag;
        }
        &--awaiting {
          background-color: $awaiting-tag;
        }
        &--fulfilled {
          background-color: $fulfilled-tag;
        }
        &--flagged {
          background-color: $flagged-tag;
        }
        &--closed {
          background-color: $closed-tag;
        }
        &--refund {
          background-color: $refund-tag;
        }
        &--release {
          background-color: $release-tag;
        }
        &--stop {
          background-color: $stop-tag;
        }
      }

      &-text {
        @include medium-font;
        font-size: $font-size-1;
        margin-left: 8px;
      }
    }

    &-detail {
      &__header {
        display: none;
        @include desktop {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          height: 48px;
          margin-bottom: 8px;
        }

        &-menu {
          display: flex;
          flex-direction: row;

          &__item {
            font-size: $font-size-2;
            border-bottom: 2px solid transparent;
            padding: 15px 18px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:first-child {
              margin-left: 0px;
            }
            &:last-child {
              margin-right: 0px;
            }

            &.active {
              @include medium-font;
              border-bottom: 2px solid $orange;
            }
          }
        }
      }
      &__container {
        display: none;
        @include desktop {
          height: auto;
          background-color: transparent;
          position: relative;
          padding: 24px;
          padding-top: 0px;
          box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
          background-color: #f4f5f7;
          display: flex;
          flex-direction: column;
        }

        & .list-item__indicator {
          height: 90%;
          top: calc((100% - 90%) / 2);
        }

        &-close {
          width: 64px;
          height: 24px;
          border-radius: $border-radius-md;
          background-color: #e7e8eb;
          align-self: flex-end;
          font-size: $font-size-1;
          @include medium-font;
          color: $light-gray;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin: auto 0px;
          margin-left: auto;
          outline: none;
        }
      }
      &__main {
        width: 100%;
        height: 100%;
        border-radius: $border-radius-2;
        border: solid 1px #eaecee;
        background-color: $white;

        &-item {
          width: 100%;
          height: 48px;
          padding: 16px 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          border: solid 0.5px rgba($color: #979797, $alpha: 0.5);
          border-right: 0px;
          border-left: 0px;
          &-alternate {
            min-height: 48px;
            height: auto;
            & > div {
              display: flex;
              flex-direction: column;
              margin-bottom: auto;
            }
          }
          &:first-child {
            border-top: 0px;
          }
          &:last-child {
            border-bottom: 0px;
          }

          &__title {
            font-size: $font-size-2;
            @include medium-font;
            color: #46505e;
          }
          &__details {
            color: #778597;
            font-size: $font-size-2;
          }

          & .button {
            margin: 0px 8px;
            &:first-child {
              margin-left: 0px;
            }
            &:last-child {
              margin-right: 0px;
            }
          }

          & .button--orange {
            margin-top: -8px;
          }
        }
      }
    }

    &__image {
      width: 48px;
      height: 48px;
      object-fit: contain;
      border-radius: $border-radius-1;
      margin-right: 24px;

      &-placeholder {
        background-color: $gainsboro;
      }
    }
    & .button--orange-outline {
      margin-left: 24px;
    }
  }

  &-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    @include desktop {
      display: none;
    }
    &-overlay {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0.7;
      background-color: $overlay;
      height: 100vh;
      // pointer-events: auto;
      @include desktop {
        display: none;
      }
    }

    &__body {
      position: absolute;
      //  top: 350px;
      left: 0;
      right: 0;
      bottom: 0;
      height: auto;
      z-index: 1000;
      box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
      background-color: $white;
    }

    &__header {
      display: flex;
      flex-direction: row;
      padding: 12px 24px;
      border-bottom: 1px solid $border-color-1;

      &-label {
        font-size: $font-size-2;
        font-weight: normal;
        padding: 16px 16px;
        border-bottom: 2px solid transparent;
        transition: all 0.2s ease-in-out;
        // margin: 0px 4px;
        &:first-child {
        }

        &.active {
          @include medium-font;
          border-bottom: 2px solid $orange;
        }
      }
    }

    &__list {
      // min-height: calc( 100vh - 350px);
      // display: flex;
      // flex-flow: column nowrap;

      &-item {
        border-bottom: 1px solid $border-color-1;
        padding: 12px 24px;
        height: auto;

        &__alternate {
          padding: 16px 24px;
          border: none;
          margin-top: auto;

          & .button {
            margin: 8px 0px;
            &:first-child {
              margin-top: 0px;
            }
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }

        & .list-item__status-text {
          @include medium-font;
          margin-left: 0px;
        }
        & .list-item__payment-container {
          justify-content: space-between;
        }
        & .list-item__details-product__image-container {
          margin-top: 16px;
        }

        &--alt {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 16px 24px;
        }
      }
    }

    & .list-header__action-menu__body,
    .list-header__action-menu__alt__body {
      padding: 24px;
    }
  }
}
