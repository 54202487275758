$primary-color:#FE8F01;;
.product-detail{
    .product-image-wrapper{
        width: 100%;
        height: 100%;
        max-width: 420px;
        max-height: 528px;
        background-color: aliceblue;

        .main-product{
            width: 100%;
            height: 100%;
            max-width: 420px;
            max-height: 528px;
        }
    }
    .product-image-slide{
        width: 100%;
        height: 100%;
        max-width: 120px;
        max-height: 170px;
        cursor: pointer;
    }
.product-name{
    margin-top: 10px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    @include desktop{
        margin-top: auto;
        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        line-height: 58px;
    }
}
.product-text{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}
.product-price{
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 42px;
    color: $primary-color
}
.quantity{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.action-wrapper{
    display: flex;
    align-items: center;
    gap: 30px;

    @include desktop{
        display: flex;
        align-items: center;
        gap: 20px;
    }
    button{
        border:1px solid rgba(24, 25, 31, 0.35);
        padding: 7px 15px;
        font-size: 1rem;
        cursor: pointer;

        @include desktop{
            border:1px solid rgba(24, 25, 31, 0.35);
            padding: 15px 20px;
            font-size: 1rem;
            cursor: pointer;
        }
    }

    .plus-button{
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-left: none;
    }
    .minus-button{
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        border-right: none;
        }
        
    .cart-button{
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 7px 36px;
        background: $primary-color;
        border: 1px solid $primary-color;
        border-radius: 10px;
        color:white;
        @include desktop{
            box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 38px;
        background: $primary-color;
        border: 1px solid $primary-color;
        border-radius: 10px;
        color:white;
        }
    }
}
.share{
    margin-top: 1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .social{
        display: flex;
        gap: 10px;
    }
}
.tab-nav-section{
    display: flex;
    gap: 40px;
    justify-content: center;
    .item{
        padding: .8rem 0px;
        cursor: pointer;
    }
    .active-tab{
        border-top: 3px solid $primary-color;
        color: $primary-color;
    }
}
.product-information{
    margin-bottom: 5rem;
    .tab-content{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
    }
}
.similar-product-header{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #18191F59;
    border-top: 1px solid #18191F59;
    padding: .6rem;
    margin-bottom: 2rem;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    @include desktop{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #18191F59;
        border-top: 1px solid #18191F59;
        padding: .6rem;
        margin-bottom: 2rem;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 48px;
    }
}
    
}