

.loader__container{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loader__processing {
    display: flex;
    justify-content: center;
  
    div {
      width: 1rem;
      height: 1rem;
      margin: 2rem 0.3rem;
      background: $purple;
      border-radius: 50%;
      animation: 2s bounce infinite alternate;
  
      &:nth-child(2) {
        animation-delay: 0.3s;
      }
  
      &:nth-child(3) {
        animation-delay: 0.6s;
      }
    }
  }
  
  @keyframes bounce {
    to {
      opacity: 0.3;
      transform: translate3d(0, -1rem, 0);
    }
  }

  .donut {
    width: 2rem;
    height: 2rem;
    margin: 2rem;
    border-radius: 50%;
    border: 0.3rem solid rgba($purple, 0.3);
    border-top-color: $purple;
    animation: 1.5s spin infinite linear;
  
    &.multi {
      border-bottom-color: $purple;
    }
  }