.radio__container {
    --text: #414856;
    --radio: #7C96B2;
    --radio-checked: #5E60CE;
    --radio-size: 18px;
    --width: 140px;    
    --height: 140px;
    --border-radius: 10px;
    border-radius: var(--border-radius);
    color: var(--text);
    position: relative;
    display: grid;
    grid-template-columns: auto var(--radio-size);
    gap: .6rem;
    label {
      cursor: pointer;
    }
    input[type="radio"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      position: relative;
      height: var(--radio-size);
      width: var(--radio-size);
      outline: none;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--radio);
      background: transparent;
      border-radius: 50%;
      display: grid;
      justify-self: flex-start;
      justify-items: center;
      align-items: center;
      overflow: hidden;
      transition: border .5s ease;
      &::before,
      &::after {
        content: "";
        display: flex;
        justify-self: center;
        border-radius: 50%;
      }
      &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--background);
        z-index: 1;
        opacity: var(--opacity, 1);
      }
      &::after {
        position: relative;
        width: calc(100% /2);
        height: calc(100% /2);
        background: var(--radio-checked);
        top: var(--y, 100%);
      }
      &:checked {
        --radio: var(--radio-checked);
        &::after {
          --y: 0%;
        
        }
        &::before {
          --opacity: 0;
        }
        ~ input[type="radio"] {
          &::after {
            --y: -100%;
          }
        }
      }
      &:not(:checked) {
        &::before {
          --opacity: 1;
        }
      }
    }
  }
  
  