.search__wrapper{
	.search_wrap {
    .search_box{
      position: relative;
      width: 100%;
      max-width: 600px;
      height: 40px;
      padding: 1.2rem;
  
      .input{
        width: 100%;
        position: absolute;
        border-radius: 10px;
        background: #FBFBFB;
        color: #2C314980;
        border: 1px solid #FBFBFB;
        height: 40px;
        font-size: 12px;
        letter-spacing: normal;
        line-height: normal;
        outline: $purple !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px 20px;
        font-size: 14px;
            @include medium-font;
            &::placeholder {
              color: #A3A1A1;
              font-size: 12px;
            }
            
            &:focus{
                border:none;
            }
            &.error {
              border: solid 1px $error;
              color: $error;
              &::placeholder {
                color: $error;
              }
            }
            &.disabled {
              background-color: #f5f5f7;
              cursor: not-allowed;
            }
        
      }
      .btn{
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 100%;
        background: #FBFBFB;
        z-index: 1;
        cursor: pointer;
      }
      
    }
  }
  .search_wrap_2 {
    .search_box {
      .btn {
        left: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      .input{
        padding-left: 50px;
        width: 250px;
        @include desktop{
          padding-left: 50px;
          width: 400px;
        }
      }
    }
  }

	
  .search_wrap_3 {
    .search_box{
      position: relative;
      width: 300px;
      height: 40px;
      @include desktop{
        position: relative;
        width: 400px;
        height: 40px;
      }
      .search__icon__append{
        position: absolute;
        top: 6px;
        left: 10px;
        width: 40px;
        height: 100%;
        z-index: 1;
      
      }
      .input{
        padding-left: 30px;
        border:1px solid $purple;
        font-size: 14px;
        &:focus{
          border:1px solid $purple;
        }
      }
      .btn{
        right: 0px;
        border-radius: 10px;
        background: $purple;
      }
      .btn__prepend{
        position: absolute;
        top: 0;
        right: 0;
        width: 90px;
        height: 100%;
        background: $purple;
        color: white;
        z-index: 1;
        cursor: pointer;
      
    }
    }

  }

  .custom__searchBox {
    .search_box {
      .btn {
        left: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        background: transparent;
      }
      .input{
        padding-left: 50px;
        max-width: 100%;
        background: transparent;
        @include desktop{
          padding-left: 50px;
          max-width: 100%;
        }
      }
      &.box__border{
        border: 1px solid #CFD3D4;
       
        border-radius: 12px;
       
      }
    }
  }

 

	
}