.cart {
  padding: 16px;
  //   min-height: 100vh;

  @include desktop {
    margin-top: 32px;
    padding: 0px;
  }

  &-divider {
    width: 100%;
    height: 1px;
    background-color: $border-color-1;
    opacity: 0.53;
  }

  & .button {
    font-size: $font-size-1;
  }

  & .summary {
    order: -1;
    @include desktop {
      order: 0;
    }

    & .pcard-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 16px;
      margin-bottom: 24px;
    }
    & .button {
      margin: 8px 0px;
      &:first-child {
        margin-top: 0px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  &-list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &-item {
    width: 100%;
    height: auto;
    border-radius: $border-radius-2;
    box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
    background-color: $white;
    padding: 16px 0px;
    margin: 8px 0px;
    @include desktop {
      height: auto;
    }
    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }

    &__title {
      font-size: $font-size-2;
      font-weight: 500;
      font-family: $medium-font !important;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @include desktop {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-bottom: 4px;
      }
    }
    &__info {
      font-size: $font-size-2;
      color: $gray;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @include desktop {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin: 4px 0px;
      }
    }

    &__image {
      &-wrapper {
        background-color: #dfe2e8;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg {
          width: 36px;
          height: 36px;
          @include desktop {
            width: 56px;
            height: 56px;
          }
        }
      }
    }
  }
}
