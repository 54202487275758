//order details
.invoice__detail{
    .invoice__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 1.5rem 0rem;
        .title{
            color: #495057;
            font-size: 24px;
            font-weight: 700;
        }
        .invoice__button{
            border-radius: 10px;
            background: rgba(94, 96, 206, 0.15);
            color: $purple;
            font-size: 16px;
            font-weight: 500;
            padding: 14px 20px;
            outline: none;
            border: none;
        }
    }
    .invoice__container{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 2rem;
        padding-bottom: 3rem;
        
        .invoice__details{
            display: flex;
            width: 100%;
            padding: 1.875rem;
            flex-direction: column;
            gap: 30px;
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.10);
            background: #FFF;
            box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.04);
            @include desktop{
                width: 55%;
            }
            .title{
                color: #21252A;
                font-size: 20px;
                font-weight: 600;
            }
            .invoice__info{
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 1rem;
                .info__item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: rgba(24, 25, 31, 0.70);
                    font-size: 16px;
                    font-weight: 400;
                }
                .table__container{
                    overflow-x: auto;
                    .invoice__item__table{
                        width: 100%;
                        thead tr th{
                            padding-bottom: 1rem;
                        }
                        tbody{
                            tr{
                                &:first-child{
                                    border-top: 1px solid rgba(0, 0, 0, 0.10);
                                }
                            }
                            td{
                                padding: .5rem 0rem;
                                border-bottom: 1px solid rgba(0, 0, 0, 0.10);
                                
                            }
                        } 
                        tbody td{
                        vertical-align: top;
                        }
                    }
                    .total__section{
                        display: flex;
                        justify-content: space-between;
                        padding: .6rem 0rem;
                        color: #21252A;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                    }
                    .item__right{
                        text-align: right;
                    }
                }
                
               
            }
        }


        .customer__details{
            width: 100%;
            @include desktop{
                width: 41%;
            }
            .title{
                color: #21252A;
                font-size: 20px;
                font-weight: 600;
            }
            .customer__info{
                display: flex;
                padding: 1.875rem;
                flex-direction: column;
                gap: 24px;
                border-radius: 10px;
                border: 1px solid rgba(0, 0, 0, 0.10);
                background: #FFF;
                .customer__card{
                    display: flex;
                    flex-direction: column;
                    gap: .6rem;
                    .card__item{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: rgba(24, 25, 31, 0.70);
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                    }
                    .label{
                        color: rgba(24, 25, 31, 0.70);
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                    }
                    .item__title{
                        color: #21252A;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
                
            }
            .cancel__order__button{
                color: #F00;
                border: none;
                outline: none;
                cursor: pointer;
                width: max-content;
                margin: .7rem auto;
                background: transparent;
                text-align: center;
               
                
            }
        }
    }
}