.messaging{
    margin-top: 2rem;
    overflow: hidden;
    .title__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        .page__title{
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #495057;
        }
    }
    .chat__wrapper{
        background: #F5F5F5;
        display: flex;
        gap: .5rem;
        border-radius: 12px;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        height: 100vh;
        overflow: hidden;
        .contact__container{
            width: 100%;
            border-radius: 12px;
            padding: 22px 0px;
            background: white;
            max-height: 100%;
            @include desktop {
                width: 40%;
            }
            .header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0rem;
                box-shadow: none;
                @include desktop{
                    padding: .5rem 1.5rem;
                }
                .title{
                    font-size: 1.25rem;
                    font-weight: 500;
                }
                .chat__number{
                    color: #A6A8B1;
                    font-size: 1.25rem;
                    font-weight: 500;
                }
            }
            .search__wrapper{
                padding: .5rem 0rem;
                width: 100%;
                @include desktop{
                    padding: .5rem 1.5rem;
                }
            }
        }

        .chat__container{
            width: 100%;
            flex: 1;
            border-radius: 12px;
            padding: 1rem 1.4rem;
            background: white;
            max-height: 100%;
            overflow-y: auto;
            @include desktop{
                width: 50%;
            }
        }
    }
    
}