.top__header__container{
    background: #FFFFFF;
    
    box-shadow: 0px 6px 10px rgba(24, 25, 31, 0.1);
    padding: .6rem 0rem;
    @include desktop{
        box-shadow: 0px 1px 0px #E5E9F2;
    }
}
.nav__wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    color: #18191F;
    
    .active__nav{
        color: $orange;
    }
}
.header__navigation{
    display: flex;
    justify-content: space-between;
    align-items: center;
.store__download{
    display: flex;
    gap: 2rem;
    align-items: center;
    a{
        text-decoration: none;
        &:hover{
            color: inherit;
        }
    }
}
 
}
//mobile nav
.mobile__nav_wrapper{
    
    .mobile__nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
 
    }
    .dropdown__menu_wrapper{
        background-color: #FFFFFF;
        position: absolute;
        z-index: 2;
        width: 100%;
        .dropdown__menu {
            padding: 2rem 1.5rem;
            display: flex;
            flex-direction: column;
            gap: .7rem;
            
            
           a{
            color: #18191F;
            &:hover{
                color: #5D5FCB;
                cursor: pointer;
            }
           }
            
        }
    }
    
}