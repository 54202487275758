.payments{
    table{
        border: 0px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #495057;
    }
    .customer__info{
        display: flex;
        gap: .5rem;
        .buyer__image{
            img{
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }
            
        }
        .buyer__email{
            font-weight: 300;
            font-size: 10px;
            line-height: 12px;
            color: #495057;
        }
    }
    
    .title__text{
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #495057;
        @include desktop{
            margin-top: 60px;
            margin-bottom: 1rem;
            font-size: 24px;
            line-height: 29px;
        }
    }
    .release__code{
        display: flex;
        gap: 16px;
        align-items: center;
        .release__code__action{
            height: 34px;
            width: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0, 0, 0, 0.05);
            border-radius: 50%;
        }
    }
    .coming__soon{
        font-weight: 800;
        font-size: 20px;
        line-height: 39px;
        text-align: center;
        margin: 2rem auto;
    }
    .payment__tabs{
        display: flex;
        gap: 2rem;
        .tab{
            padding:.5rem 0rem;
            cursor: pointer;
            transition: 0.4ms ease-in-out;
        }
        .active__payment__type{
            border-bottom: 2px solid $purple;
        }
    }
    
}