.modal {
  outline: none;
  padding: 20px;
  border-radius: 8px;
  border: none !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app-modal-content {
  border-radius: 10px;
  padding: 30px;
  background: #ffffff;
  border: none;
  margin: auto !important;
  max-height: 90vh;
  width: 100%;
  max-width: 50vw;
  overflow: auto;
}
.overlay {
  background: rgba(0, 0, 0, 0.45);
  position: fixed;
  inset: 0px;
}

.app-modal-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #344054;
}
@media screen and (max-width: 768px) {
  .app-modal-content {
    margin: 0 auto !important;
    max-height: 80vh;
    width: 100%;
    max-width: 100vw;
    overflow: auto;
    padding: 20px;
  }
}
