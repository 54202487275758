.support {
  .accordion-button {
    padding-inline: 0 !important;
    outline: 0 !important;
    &:not(.collapsed) {
      outline: 0 !important;
      background-color: transparent !important;
    }
    &.collapsed {
      outline: 0 !important;
      background-color: transparent !important;
    }
  }
  .accordion__title {
    font-style: normal;
    font-weight: 700 !important;
    line-height: 27px !important;
  }
}
