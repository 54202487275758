.plan{
    
    .plan__header{
        margin-top: 60px;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #495057;
    }
    .plan__wrapper{
        margin-top: 36px;
        display: flex;
        flex-wrap: wrap;
        @include desktop{
            flex-wrap: nowrap;
        }
        gap: 2rem;
        .plan__card{
            width: 100%;
            max-width: 608px;
            padding: 1rem 1.5rem;
            border: 0.5px solid rgba(133, 133, 133, 0.26);
            box-shadow:(2px 6px 25px rgba(0, 0, 0, 0.09));
            border-radius: 10px;
            .plan__price{
               
                font-size: 20px;
                line-height: 24px;
                color: rgba(24, 25, 31, 0.45);
                .price{
                    color: $purple;
                    font-weight: 600;
                }
            }
            .card__header{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: .5rem;
                .plan__type__wrapper{
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    gap: 1rem;
                    .plan__type{
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        color: #495057;

                    }
                    .plan__status{
                        padding: 6px 10px;
                        gap: 10px;
                        width: 57px;
                        height: 27px;
                        background: rgba(0, 255, 0, 0.35);
                        border-radius: 45px;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        color: #219653;
                    }
                    .plan__mode{
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        color: #495057;
                    }
                }
            }
            .card__content{
                margin-top: 44px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 1rem;
               
              .card__item{
                    .card__label__label{
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: rgba(24, 25, 31, 0.45);
                    }
                    .content{
                        margin-top: .4rem;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: $color-black;

                    }
                }
            }
            .content__divider{
                background: rgba(0, 0, 0, 0.3);
                height: 0px;
                margin: 1.4rem 0rem;
            }
            .card__footer{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: $purple;
            }
            
        }
        .payment__card{
            width: 100%;
            max-width: 431px;
            border: 0.5px solid rgba(133, 133, 133, 0.26);
            box-shadow:(2px 6px 25px rgba(0, 0, 0, 0.09));
            border-radius: 10px;
            padding: 1rem 1.5rem;
            .card__header{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .title{
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    color: #495057;
                }
            }
            .card__content{
                margin-top: 44px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
               
              .card__item{
                padding: 0px;
             
                    .card__label__label{
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: rgba(24, 25, 31, 0.45);
                    }
                    .content{
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: $color-black;

                    }
                }
            }
            .content__divider{
                background: rgba(0, 0, 0, 0.3);
                height: 0px;
                margin: 1.4rem 0rem;
            }
            .card__footer{
                font-weight: 500;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                line-height: 19px;
                color: $purple;
                align-items: center;
                p{
                    margin-top: .8rem;
                }
            }
        }
    }
    .billing__history__wrapper{
        margin: 1.5rem 0rem;
        .title{
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #495057;
        }
        .invoice__number{
            color:#2F80ED;
        }
    }
    
}