@import "utils/_typography.scss";

// New - dashboard colors
$primaryBlack: #0b0d17;
$lightOrange: #fe8f01;
$footerGray: #f4f5f7;
$color-black:#18191F;
$primary-gray:#F2F2F2;

//colors
$white: #ffffff;
$black: #10182a;
$purple:#5E60CE;
$orange: #ff721b;
$dark: #313639;
$gray: #707479;
$grey: rgba(204, 204, 204, 0.527);
$whitesmoke: #f2f3f5;
$blue: #4367b2;
$gainsboro: #e4e4e4;
$oldlace: #fef9e8;
$lavender: #e5e7eb;
$red: #ff5a5a;
$green: #01bf7c;
$yellowish: #ffecd0;
$brown: #904306;
$light-gray: #878b94;
$modal-color: #e4e5ea;
$silver: #c7c7c7;

$overlay: #6f6f6f;
$error: #d80000;

//status color
$pending: #ff8900;
$awaiting: #4d8fca;
$open: #d7e233;
$fulfilled: #5dc28b;
$initial: #f4cb77;
$flagged: #e45646;
$closed: #868a8e;
$refund: #4ed34a;
$released: #2995b5;
$stop: #cf347c;
$cancel: #ff1b00;

//status tag color
$pending-tag: #f5dbc5;
$awaiting-tag: #cae1fe;
$initial-tag: #ffe7b5;
$open-tag: #ebef97;
$fulfilled-tag: #b4edce;
$flagged-tag: #f0c5be;
$closed-tag: #dadbdc;
$refund-tag: #ceefc1;
$release-tag: #caf3ff;
$stop-tag: #ecd3d2;

//text color
$pending-text-color: #b74803;
$awaiting-text-color: #29396d;
$initial-text-color: #60450f;
$open-text-color: #51540c;
$fulfilled-text-color: #2b4624;
$flagged-text-color: #951204;
$closed-text-color: #474545;
$refund-text-color: #084c05;
$release-text-color: #0a485a;
$stop-text-color: #a72461;

$base-font-size: 16px;



//Font-sizes
$font-size-xs: font-size(10px);
$font-size-0: font-size(8px);
$font-size-1: font-size(12px);
$font-size-2: font-size(14px);
$font-size-3: font-size(16px);
$font-size-4: font-size(18px);
$font-size-5: font-size(24px);
$font-size-6: font-size(26px);
$font-size-7: font-size(28px);
$font-size-8: font-size(32px);
$font-size-md: font-size(26px);
$font-size-small: font-size(20px);
$font-size-smaller: font-size(22px);
$font-size-large: font-size(40px);
$font-size-largest: font-size(48px);
$font-size-semi: font-size(56px);
$font-size-max: font-size(64px);


//border radius
$border-radius-1: 2px;
$border-radius-2: 4px;
$border-radius-3: 8px;
$border-radius-md: 16px;
$border-radius-lg: 24px;

//border-color
$border-color-1: rgba(
  $color: #979797,
  $alpha: 0.5,
);

//z-index
$z-index-1: 1;
$z-index-2: 3;
$z-index-3: 10;
$z-index-4: 50;

//breakpoints
$mobile-width: 576px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin mobileAndTablet {
  @include mobile {
    @content;
  }
  @include tablet {
    @content;
  }
}

@mixin medium-font {
  font-family: $medium-font;
  font-weight: 500;
}

@mixin primary-font {
  font-family: $primary-font;
  font-weight: normal;
}
