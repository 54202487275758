.quantityWrapper {
    display: flex;
}

.quantityBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    /* aspect-ratio: 1; */
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
}

.quantityBtn.left {
    border-radius: 8px 0px 0px 8px;
}

.quantityBtn.right {
    border-radius: 0px 8px 8px 0px;
}

.quantityInput {
    padding: 2px 0;
    height: 40px;
    width: 50px;
    text-align: center;
    background: #FFFFFF;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #495057;
    border: 1px solid #D0D5DD;
}

/* Chrome, Safari, Edge, Opera */
.quantityInput::-webkit-outer-spin-button,
.quantityInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.quantityInput[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}