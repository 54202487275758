.merchant {
  margin-bottom: 150px;
  padding-top: 24px;
  padding-right: 16px;
  padding-left: 16px;
  @include desktop {
    margin-top: 32px;
  }

  @include desktop {
    padding-right: 0px;
    padding-left: 0px;
    min-height: 90vh;
  }
  & .list-header {
    margin-bottom: 12px;
  }
  &-product {
    &-card {
      height: auto;
      width: 100%;
      border-radius: $border-radius-2;
      box-shadow: 0 10px 20px 0 rgba(231, 231, 231, 0.5);
      background-color: $white;
      padding: 16px 0px;
      display: flex;
      flex-direction: column;
      margin: 12px 0px;
      position: relative;
      @include desktop {
        flex-direction: column;
        height: 424px;
        padding: 16px;
      }

      &__top {
        display: flex;
        flex-direction: row;
        padding: 0px 16px 16px 16px;
        @include desktop {
          flex-direction: column;
          padding: 0;
        }
      }

      &__details {
        margin-left: 8px;
        height: 64px;

        @include desktop {
          margin-top: 8px;
          margin-left: 0px;
        }

        &__base {
          display: none;
          @include desktop {
            display: block;
          }
        }
      }

      &__mobile-base {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;
        padding: 16px;
        padding-bottom: 0px;
        border-top: 1px solid $border-color-1;

        @include desktop {
          display: none;
        }

        & .button {
          width: auto;
          padding: 0px 16px;
          margin: 0px !important;
          font-size: $font-size-1;
        }
      }

      &__title {
        width: 100%;
        height: 36px;
        font-size: $font-size-2;
        line-height: 1.2rem;
        color: $black;
        text-align: left;
        margin-bottom: 4px;
        @include medium-font;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      &__info {
        width: 100%;
        height: 36px;
        font-size: $font-size-2;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2rem;
        letter-spacing: normal;
        color: #778597;
        text-align: left;
        margin-top: 4px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        @include desktop {
          margin-bottom: 6px;
        }
      }

      &__amount {
        // width: 85px;
        // height: 18px;
        font-size: $font-size-2;
        font-weight: 600;
        line-height: 1.29;
        color: $black;
        @include medium-font;
      }
      &__slash_price{
        text-decoration-line: line-through;
      }

      &__delivery-day {
        // width: 106px;
        // height: 18px;
        font-size: $font-size-1;
        line-height: 1.5;
        text-align: right;
        color: $dark;
      }

      & .button {
        font-size: $font-size-1;
        margin-top: 16px;
      }

      &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &-wrapper {
          width: 64px;
          height: 64px;
          min-width: 64px;
          background-color: #dfe2e8;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          @include desktop {
            width: 100%;
            height: 206px;
            margin-bottom: 8px;
            margin-right: 0px;
          }
        }
      }
    }
  }
}
