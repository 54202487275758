.mBadge{
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: $medium-font;
    font-size: $font-size-2;

    &--secondary{
        background-color: $red;
        color: $white;
    }

    &-alt{
        border-radius: $border-radius-1;
        background-color: $white;
        padding: 2px 4px;
        margin-left: 8px;
        font-family: $medium-font;
        font-size: $font-size-1;
        font-weight: 600;
        color: #4f4f4f;
    }
}


.order__status{
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    border-radius: 15px;
    text-align: center;
    padding: .4rem .6rem;
    width: 80px !important;
   
}
.status__shipped{
    background: rgba(0, 255, 0, 0.3);
    color: #0a3b2b;
}
.status__confirmed{
    background: rgba(179, 252, 179, 0.3);
    color: #00A36C;
}
.status__completed{
    background: #5cb85c;
    color: #ffffff;
}
.status__in__progress{
    background: #428bca;
    border-radius: 15px;
    color: #ffffff
}

.status__canceled{
    background: rgba(255, 0, 0, 0.15);
    border-radius: 15px;
    color: #FF0000;
}

.status__disputed{
    background: rgba(255, 0, 0, 0.15);
    border-radius: 15px;
    color: #FF0000;
}

.status__declined{
    background: rgba(255, 0, 0, 0.15);
    border-radius: 15px;
    color: #FF0000;
}

.status__pending{
    background: #f0ad4e;
    border-radius: 15px;
    color: #ffffff;
}
.status__paid{
    background: #337ab7;
    color: #ffffff;
}
.status__unpaid{
    background: #777777;
    border-radius: 15px;
    color: #ffffff;
}
.status__accepted{
    background: rgba(33, 150, 83, 0.15);
    color: #219653;
}

.status__delivered{
    background: #32CD32;
    color: #ffffff
}

.payment__status{
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    border-radius: 15px;
    text-align: center;
    padding: .4rem !important;
    margin: 1rem auto;
    width: 100px;
    display: block;
}