$font-family: 'Inter';

.top-selling{
    .category-card{
        position: relative;
        width: 100%;
        height: auto;
        max-width: 25.8rem;
        max-height: 184px;
        display: flex;
        flex-direction: column;
        margin: 0px;
        padding: 0px;
        @include desktop{
            position: relative;
            width: 100%;
            max-width: 22.70rem;
            display: flex;
            flex-direction: column;
            background: white;
        }

        .category-image{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .category-shop-now{
            position: absolute;
            bottom: 26px;
            left: 30px;
            font-weight: 800;
            font-size: 12px;
            line-height: 15px;
            color: #18191F;
            background: white;
            padding: 5px;
            z-index: 3;
        }
    }
        .title{
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;
            color: #FFFFFF;

            @include desktop{
                font-family: $font-family;
                font-style: normal;
                font-weight: 800;
                font-size: 28px;
                line-height: 34px;
                color: #FFFFFF;
            }
        }
        .text{
            font-family: $font-family;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #FFFFFF;

            @include desktop{
                font-family: $font-family;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
                margin-top:8px;
            }
        }
        .more{
            font-family: $font-family;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #FFFFFF;
            cursor: pointer;
            text-decoration: none;
        }

    } 