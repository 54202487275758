.chat__contacts{
    overflow-y: auto;
    height: 100%;
    .chat__contact__item{
        border-bottom: 1px solid  #F1F3F9;
        cursor: pointer;
        &.active__item{
    
            border-right: 4px solid #5570F1;
            background: #F7F7FC;
        }
        .chat__item__wrapper{
            display: flex;
            gap: 1rem;
            padding: 0.75rem 1.5rem;
            .image__user{
                width: 40px;
                height:40px;
            }
            .chat__info{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: 1;
                .user__detail{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .username{
                        color:#45464E;
                        font-size: 16px;
                        font-weight: 500;
                    }
                    .message__badge{
                        color:  #1C1D22;
                        font-size: 12px;
                        font-weight: 400;
                        padding: 2px 7px;
                        border-radius: 8px;
                        background: #FEF5EA;
                    }
                    .message__unread{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        width: 24px;
                        height: 24px;
                        background:  #FFCC91;
                        font-size: 12px;
                    }
                }
            }
            .message__wrapper{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .message__container{
                    .message{
                        color:#8B8D97;
                        font-size: 14px;
                        font-weight: 400;
                    }
                    
                }
                .message__timer{
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
    }
}