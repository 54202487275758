
$primary-color:#FE8F01;
$red:#b00020;
.floating__label__content {
  position:relative; 
  margin-bottom:18px; 
}
.password__input__wrapper{
  display: flex;
  align-items: center;
  .password__toggle{
    margin-left: -2.2rem;
  }
}
.floating__label {
  color: #A3A1A1;
  font-size:13px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:15px;
  top:15px;
  padding:0 5px;
  background:#fff;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}
.floating__input, .floating__select {
  font-size:.875rem;
  display:block;
  width:100%;
  height: 50px;
  border: 1px solid #D2D0D0;
  border-radius: 4px;
  padding: 0 16px;
  background: #fff;
  color: #323840;
  box-sizing: border-box;
  &:focus{
    outline:none;
    border:1px solid $purple;
    ~ .floating__label{
      top:-8px;
      font-size:.875rem;
    }
  }
}

select.floating__select {
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
select.floating__select::-ms-expand {
  display: none;
}

.floating__input:not(:placeholder-shown) ~ .floating__label {
  top:-8px;
  font-size:.875rem;
}
.floating__select:not([value=""]):valid ~ .floating__label {
  top:-8px;
  font-size:.875rem;
}
.floating__select[value=""]:focus ~ .floating__label {
  top:11px;
  font-size:.875rem;
}
.floating__select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
    background-position: right 15px top 50%;
    background-repeat: no-repeat;
}

.error__input{
  .floating__input, .floating__select {
    border: 1px solid $red;
    color:$red;
  }
  .floating__label{
    color:$red;
  }
  .error__message{
    color: $red;
    font-size: 12px;
  }
  .floating__select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
}
}

.input__group {
  display: flex;
  .floating__input{
    border-radius:0 4px 4px 0;
    border-left:0;
    padding-left:0;
  }
}
.input__group__append {
  display:flex;
  align-items:center;
/*   margin-left:-1px; */
}
.input__group__text {
    display: flex;
    align-items: center;
    font-weight: 400;
    height: 50px;
    border: 1px solid #D2D0D0;
    border-radius: 4px;
    font-size:.875rem;
    background: #fff;
    color: #323840;
    padding: 0 5px 0 16px;
    text-align: center;
    white-space: nowrap;
    border-radius: 4px 0 0 4px;
    border-right:none;
}
