$primary-color:#FE8F01;
$dark-color: #18191F;
$font-family: 'Inter';
body{
    font-family: $font-family;
}
.buyerexperience{
    .layout__wrapper{
        margin-bottom: 15rem;
    }

    .w-full{
        width: 100%;
    }
    .arrow-coloured{
        width: 100%;
        background: url(/assets/images/svg/down-chevron-orange.svg) no-repeat 96% 53%;
    }
    select {
        height: 48px;
        width: 100%;
        padding: 8px 20px;
        border: 0px;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; /* remove default arrow */
        
        background: url(/assets/images/svg/chevron.svg) no-repeat 96% 53%; /* add custom arrow */
        background-color: white;
        @include medium-font;
        line-height: normal;
        outline: none;
        &:focus {
          outline: none;
        }
    }
    a{
        &:hover{
            color: inherit;
            text-decoration: none;
        }
    }
    .form_input{
        width: 100%;
        height: 40px;
        padding: 7px 15px;
        border: 1px solid rgba(24, 25, 31, 0.35);
        border-radius: 4px;

        &:focus{
            border: 1px solid rgba(24, 25, 31, 0.35);
            outline: none;
        }
    }
    background-color: white;
    font-family: $font-family !important;

    @include desktop{
        background:#F5F5F5;
    }
    .radio-button{
    $border-color: #FE8F01;
    cursor: pointer;
	-webkit-appearance: none;
    appearance: none;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	outline: none;
	border: 1.4px solid #18191F8C;
	&:before {
		content: '';
		display: block;
		width: 70%;
		height: 70%;
		margin: 15% auto;
		border-radius: 50%;
	}

	&:checked {
		&:before {
			background: #FE8F01;
		}
		border-color: $border-color;
	}

    }
    .coloured-header{
        background: #9B51E0;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 15px;
        @include desktop{
            background: #9B51E0;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 15px 15px;
        }
    }
    .cursor-pointer{
        cursor: pointer;
    }
    .text-orange{
        color:#FE8F01;
    }
    .line{
        background: #18191F59;
    }
    .cart-btn{
        width: 190px;
        height: 40px;
        background: #FE8F01;
        border-radius: 10px;
        border: none;
        color:white;
    }

    .sorting-wrapper{
        display: flex;
        justify-content: center;
        gap:5px;
        border: 1px solid #FE8F01;
        background-color: white;
        border-radius: 10px;
        width: 100%;
        max-width: 300px;
        align-content: stretch;
        height: 39px;
        padding: 4px 6px;
        align-items: center;

        .label{
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #FE8F01;
            opacity: 0.7;
        }
        .select-without-border{
            flex: 1 0 auto;
            border: none;
            color: #FE8F01;
            width: auto;
            margin: 0;
            outline: none;
            cursor: pointer;
            border: none;
            border-radius: 0;
           &:focus{
            border: none;
           }
           .options{
            padding: 30px;
           }
        }
    }
    .store-wrapper{
        display: flex;
        align-items: center;
        gap: 15px; 
    }
    
    .merchant-store{
        .store-header{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-top: 1rem;
            @include desktop {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            padding-top: 1rem;
            }
            .title{
                font-weight: 600;
                font-size: 24px;
                line-height: 39px;
                color: #18191F;
                @include desktop{
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 39px;
                    color: #18191F;
                }
               
                
            }
        }
    }

    .checkout-steps{
        align-items: center;
        display: flex;
        justify-content: center;
        height: 100px;
        background: none;

        @include desktop{
            align-items: center;
        display: flex;
        justify-content: center;
        height: 100px;
        background: #F2F2F2;
        }
      
        @include desktop{
            align-items: center;
            display: flex;
            justify-content: center;
            height: 100px;
            background: #F2F2F2;
            
        }
        .step{
            display: flex;
            align-items: center;
            gap: 10px;
            .number{
                font-weight: 600;
                font-size: 38px;
                line-height: 38px;
            }
            .description{
                p{
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 10px;
                }
                span{
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }
        
        .steps-wrapper{
            display: none;
            gap: 6rem;
            align-items: center;
            .in-active{
                color: rgb(141, 141, 141);
            }

            @include desktop{
                display: flex;
                gap: 6rem;
                align-items: center;
                .in-active{
                    color: rgb(141, 141, 141);
                }
            }
        }
        .mobile-steps-wrapper{
            display: block;
            @include desktop{
                display: none;
            }
        }
    }

    //seller form
    .form-wrapper{
        .header{
            box-shadow: none;
            text-shadow: none;
        }
        
        .form_input{
            width: 100%;
            height: 40px;
            padding: 7px 15px;
            border: 1px solid rgba(24, 25, 31, 0.35);
            border-radius: 4px;

            &:focus{
                border: 1px solid rgba(24, 25, 31, 0.35);
                outline: none;
            }
        }
    }
    hr{
        color: #18191F59;
    }
    .checkout-wrapper{
        .fees-item{
            display: flex;
            justify-content: space-between;
            align-items:baseline;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            padding: .8rem 0px .3rem 0px;
        }
        .product-toggle{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: $primary-color;
            padding: 1rem 0px 1rem 0px;
            border-bottom: 1px solid rgba(24, 25, 31, 0.15);
            border-top: 1px solid rgba(24, 25, 31, 0.15);
            
        }
        .checkout-product{
            .product-detail{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #18191F;
            }
        }
        .cart-total-items{
            border: none;
        }
        .logistic-logo{
            width: 100%;
            height: 100%;
            max-width: 64.8px;
            max-height: 36px;

            object-fit: contain;
        }
        .checkout-text{
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            text-align: center;
            a{
                color:#0047FF;
                &:hover{
                    text-decoration: none;
                }

            }
        }
    }
    .button-primary{
    margin-top: 2rem;
    margin-bottom: 2rem;
    align-items: center;
    padding: 14px;
    background: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 10px;
    color: white;
    &:disabled{
        background: rgba(254, 143, 1, 0.35);
        border-color: rgba(254, 143, 1, 0.35);
        user-select: none;
    }
    }

    .button-outline{
        margin-top: 2rem;
        margin-bottom: 2rem;
        align-items: center;
        padding: 14px;
        background: transparent;
        border: 1px solid $primary-color;
        border-radius: 10px;
        color: $primary-color
    }

    .logistic-logo{
        width: 100%;
        max-width: 210px;
        height: 100%;
        max-width: 43px;
    }
    .payment-provider{
        display: flex;
        justify-content: space-between;
        margin-top: 1.6rem;
        
    }
    .shop-now{
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
    }
    .modal-container {
        position: absolute;
        width: 100%;
        background: #ffffff;
        top: 20%;
        padding: 20px;
        margin: 2px;
        height: auto;
        overflow-y:auto ;

        @include desktop{
            position: absolute;
            width: 60%;
            background: #ffffff;
            left: 20%;
            top: 15%;
            padding: 20px;
            margin: 2px auto;
            height: auto;
            overflow-y:auto ;
        }
        .title{
            font-weight: 600;
            font-size: 16px;
            line-height: 42px;
            @include desktop{
            font-weight: 600;
            font-size: 25px;
            line-height: 42px;
            }
        }
      }
      .modal {
        background: rgba(0, 0, 0, 0.6);
        overflow-y:auto ;
      }
      .shipping{
        overflow-y: auto;
        
        .item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #F2F2F2;
            padding: .6rem;
            margin: 5px auto;
        }
      }
      .bread-crumb{
        margin: 1.5rem auto;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }
      .pagination{
        display: flex;
        gap:10px;
        justify-content: flex-end;
        padding: 1.3rem 0px;
        .page{
            box-sizing: border-box;
            max-width: 100%;
            border: 1.6px solid rgba(24, 25, 31, 0.15);;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-transform: capitalize;
            color: #18191F;
            opacity: 0.55;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 18px;
            border-radius: 2px;
            &:disabled{
                cursor: not-allowed;
            }
            
        }
        .active-page{
            background: $primary-color;
            border-color: $primary-color;
            opacity: 1;
            color: white;
        }
      }


//features styles
      .features{
       display: flex;
       flex-direction: column;
       .title{
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        color: #18191F;
       }
     .feature-wrapper{
        display: flex;
        flex-wrap: wrap;
       
        gap: 5px;
        @include desktop{
            display: flex;
            gap:5px;
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
     }

.feature-item {
    font-family: $font-family;
    width: 100%;
    max-width: 22rem;
	.title {
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
        color: $dark-color;

		@include desktop{
        font-style: normal;
		font-weight: 500;
		font-size: 1.125em;
		line-height: 19px;
		color: $dark-color;
        }
	}
	.text {
		font-family: $font-family;
		font-style: normal;
		font-weight: 400;
		font-size: 0.75rem;
		line-height: 19px;
		color: $dark-color;
	}
    .icon{
        width: 72px;
        height: 72px;
    }
    .more{
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
    }
}
      }
      //become a seller
      .create-seller{
        .terms-text{
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
          }
          .header{
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            color: #18191F;
          }
      }
      
}