.overview{
    margin-top: 15px;
    .gridlayout{
      display: flex;
      flex-direction: column;
      @include desktop{
        display: grid;
        grid-template-columns: 2fr 1fr; /* First column is twice the size of the second */
        gap: 20px;
      }

      .firstgrid{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .overview__metrics{
            display: flex;
            flex-wrap: wrap;
           @include desktop{
            height: 212px;
            border-radius: 12px;
            display: flex;
            flex-wrap: nowrap;
            border: 1px solid rgba(0, 0, 0, 0.1);
           }
            .metric__card{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                min-height: 100px;
                box-shadow: 0px 2px 10px rgba(175, 137, 255, 0.15);
                padding: 10px 0px;
                margin-top: 5px;
                @include desktop{
                //width: 128px;
                height: 205px;
                min-height: 120px;
                box-shadow: none;
                border-right: 1px solid rgba(0, 0, 0, 0.1);
                margin-top: 5px;
                }
                .card__icon{
                    width: 60px;
                    height: 60px;
                    margin-bottom: 10px;
                      
                }
                .card__title{
                    text-align: center;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14.5px;
                    color: #18191F;
                    margin: 10px 0px;
                    
                }
                .item__count{
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 24.5px;
                    margin-top: 5px;
                    text-align: center;
                    
                }
                &.visits__bg{
                    position: relative;
                    background:linear-gradient(99deg, #9C3955 0%, #F44174 100%);;
                     
                    &::before {
                        content: '';
                        position: absolute; /* Position the pseudo-element absolutely within the container */
                        bottom: -10px; 
                        left: -10px; 
                        width: 100%; /* Extend the width of the pseudo-element to cover the container */
                        height: 100%; /* Extend the height of the pseudo-element to cover the container */
                        background-image: url('/assets/images/svg/ion_stats-chart.svg');
                        background-repeat: no-repeat;
                        background-position: bottom left;   
                        
                    
                }
                  
                   
                }
                &.products__bg{
                position: relative;
                background:linear-gradient(98.86deg, #F564A9 0%, #A34D75 100%);
                 
                &::before {
                    content: '';
                    position: absolute; /* Position the pseudo-element absolutely within the container */
                    bottom: -10px; 
                    left: -10px; 
                    width: 100%; /* Extend the width of the pseudo-element to cover the container */
                    height: 100%; /* Extend the height of the pseudo-element to cover the container */
                    background-image: url('/assets/images/svg/product-box.svg');
                    background-repeat: no-repeat;
                    background-position: bottom left;   
                   
                
            }
        }
                &.orders__bg{
                    position: relative;
                    background:linear-gradient(98.86deg, #D500E3 0%, #4C1E4F 100%);
                     
                    &::before {
                        content: '';
                        position: absolute; /* Position the pseudo-element absolutely within the container */
                        bottom: -10px; 
                        left: -10px; 
                        width: 100%; /* Extend the width of the pseudo-element to cover the container */
                        height: 100%; /* Extend the height of the pseudo-element to cover the container */
                        background-image: url('/assets/images/svg/truck-tick.svg');
                        background-repeat: no-repeat;
                        background-position: bottom left;   
                    
                    
                }
                }
                &.customers__bg{
                    position: relative;
                    background:linear-gradient(98.86deg, #4BA2A4 0%, #55DDE0 100%);
                     
                    &::before {
                        content: '';
                        position: absolute; /* Position the pseudo-element absolutely within the container */
                        bottom: -10px; 
                        left: -10px; 
                        width: 100%; /* Extend the width of the pseudo-element to cover the container */
                        height: 100%; /* Extend the height of the pseudo-element to cover the container */
                        background-image: url('/assets/images/svg/user-box.svg');
                        background-repeat: no-repeat;
                        background-position: bottom left;   
                       
                    
                }
                }
                &.revenues__bg{
                    position: relative;
                    background:linear-gradient(98.86deg, #B3902A 0%, #FFD352 100%);;
                     
                    &::before {
                        content: '';
                        position: absolute; /* Position the pseudo-element absolutely within the container */
                        bottom: -18px; /* Adjust the top position as needed */
                        left: -10px; /* Adjust the left position as needed */
                        width: 100%; /* Extend the width of the pseudo-element to cover the container */
                        height: 100%; /* Extend the height of the pseudo-element to cover the container */
                        background-image: url('/assets/images/svg/dollar-circle.svg');
                        background-repeat: no-repeat;
                        background-position: bottom left;   
                        /* Adjust the size as needed */
                    
                }
                }
            }
    
            
            
        }
        // .report__wrapper{
        //     display: flex;
        //     flex-wrap: wrap;
        //     gap: 2rem;
        //     margin-bottom: 2rem;
           
        //     @include desktop{
        //         display: flex;
        //         flex-wrap: nowrap;
        //         justify-content: space-between;
        //     }
    
            .sales__report{
                width: 100%;
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
                border-radius: 10px;
                height: auto;
                padding: 1rem;
                margin-bottom: 20px;
                @include desktop{
                    background: #FFFFFF;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
                    border-radius: 10px;
                    height: auto;
                    padding: 1rem;
                    margin-bottom: 0px;
                }
                .report__header{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .title{
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        @include desktop{
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 24px;
                        }
                    }
                    .report__control{
                        display: flex;
                        gap: .3rem;
                        margin-bottom: 1.5rem;
                        @include desktop{
                        gap: .5rem;
                        margin-bottom: 1rem;
                        }
                        .button__download__report{
                            width: 100%;
                            color: $purple;
                            height: 34px;
                            border-radius: $border-radius-2;
                            border: 1px solid $purple;
                            background: transparent;
                            outline: none;
                            cursor: pointer;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            font-size: 12px;
                            line-height: 12px;
                            border-radius: 8px;
                            padding: .3rem .5rem;
                            @include desktop{
                                height: 40px;
                                padding: .8rem 1rem;
                                line-height: 15px;
                                font-size: 12px;
    
                            }
                            @include medium-font;
                            &:hover {
                                text-decoration: none;
                            }
                            &:focus{
                                outline: initial;
                                
                            }
                            
                            &:disabled {
                                opacity: .7;
                                cursor: not-allowed;
                            }
    
                        }
                        .control__label{
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                            border-radius: 8px;
                            padding: .3rem .5rem;
                            cursor: pointer;
                            @include desktop{
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                            border-radius: 8px;
                            padding: .8rem 1rem;
                            cursor: pointer;
                            }
                        }
                        .active__control{
                            background: $purple;
                            color: #FFFFFF;
                        }
                    }
                }
            }
            
        
        
      }
      .secondgrid{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .walletbox{
            width: 100%;
            background-color: #219653 ;
            background-image: url('/assets/images/wallet-pattern.png'); // Replace with the path to your image
            background-size: cover; // Adjust as needed
            background-position: center; // Adjust as needed
            background-repeat: no-repeat; // Adjust as needed
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0px 4px 15px rgba(249, 234, 234, 0.04);
            border-radius: 10px;
            height: 212px;
            @include desktop{
                width: 298px;
            }
           
            .tab-header{
              display: flex;
              margin: 22px;
            }
            .active{
                background:#fff;
                border: 1px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 4px 15px rgba(249, 234, 234, 0.04);
                border-radius: 8px; 
                padding: 8px 12px;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #5E60CE;
                //margin-right: 4px;
                cursor: pointer;
            }
            .nonactive{
                display: flex;
                border-radius: 8px; 
                padding: 6px 10px;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #fff;
                //margin-right: 4px;
                cursor: pointer;
            }
            .mywallet{
                margin-left:32px;
                color: #fff;

                .wallet-balance{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                }
                .total-balance{
                    cursor: pointer;
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 24px;
                    margin: 10px 0px 20px 0px;
                }
                .withdraw-button{
                    background:#18191F;
                    padding:7px 17px;
                    color: #fff;
                    border-radius: 8px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    outline: none;
                    border: none;
                }
            }

            .wallet-details{
                margin-left:32px;
                color: #fff;
                .bank{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                }  
                .unique-id{
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 24px;
                    //margin:5px 0px;
                    .copy{
                        margin-left: 5px;
                    }
                }
                .name{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                }
                .view-history{
                display: flex;
                align-items:center ;
                justify-content: center;
                background:transparent;
                border: 1px solid #fff;
                box-shadow: 0px 4px 15px rgba(249, 234, 234, 0.04);
                border-radius: 8px;
                height: 34px;  
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #fff;
                cursor: pointer;
                margin-top: 10px;
                width: 105px;
                }
            }
        }
        .pending-paymentbox{
            overflow:auto;
            width: 100%;
            margin: 3px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0px 4px 15px rgba(249, 234, 234, 0.04);
            border-radius: 10px;
            height: 368px;
            @include desktop{
                width: 298px;
            }
            .payment-title{
                margin:20px 0px 20px 20px;
                font-weight: 500;
                font-size: 24px;
                line-height: 24px;

            }
            .no-pending{
                margin:50px 20px 20px 20px;
                font-weight: 500;
                font-size: 16px;
                line-height: 18px;
                text-align: center;
                color: #85868a;
            }

            .payment-list{
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 61px;
                width: 100%;
                margin: 20px 0px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding: 5px 15px  ;
                @include desktop{
                    width: 290px;
                }

                .userinfo{
                 display: flex;
                 flex-direction: colun;

                 .user-icon{
                    background: rgba(1, 111, 185, 0.15);
                    padding: .2rem;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    width: 40px;
                 }

                 .username-items{
                   display: flex;
                   flex-direction: column;
                   margin-left: 15px;
                   .username{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16.94px;
                    color: #18191F;
                    margin-top: 5px;
                   }
                   .items{
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 12px;
                    color: #9FA2B4;
                    margin-top: 5px;
                   }
                 }

                }

                .paymentdetails{
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                 .amount{
                    font-weight: 800;
                    font-size: 14px;
                    line-height: 16.94px;
                 }
                 .progress{
                    display: flex;
                    align-items: center;
                    background: #c9cad6;
                    color:#5E60CE ;
                    padding: 4px 8px 4px 8px ;
                    border-radius: 15px;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                    margin-top: 5px;
                 }
                }
            }
        }
        .top__customers{
            width: 100%;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
            border-radius: 10px;
            max-height: 514px;
            height: auto;
            padding: 2rem;
            overflow-y: auto;
            @include desktop{
                width: 35%;
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
                border-radius: 10px;
                max-height: 514px;
                height: auto;
                padding: 2rem;
                overflow-y: auto;
            }
            .customer__header{
                .title{
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                }
            }
            .customers{
                max-height: 400px;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 1rem 0rem;
            }
            .customer__list__header{
                margin-top: 1rem;
                display: flex;
                gap: 1rem;
                font-weight: 600;
                justify-content: space-between;
            }
            .customer__list__item{
                display: flex;
                gap: 1rem;
                align-items: center;
                font-size: 14px;
                line-height: 19px;
                padding: .2rem 0rem;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding: 1rem 0rem;
                .user__icon{
                    background: rgba(1, 111, 185, 0.15);
                    padding: .2rem;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 36px;
                    width: 36px;
                }
                .name{
                    font-weight: 600;
                }
                .email{
                    font-size: 12px;
                }
            }
        }
    }
    .recent__orders{
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        padding: 1.5rem 0rem 0rem 0rem;
        font-family: 'Inter';
       
        .title{
            padding: 0rem 1rem;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: $color-black;
        }
    }
    .table__wrapper{
        padding: 1rem 0rem 0rem 0rem;
        overflow: auto;
        border-radius: 10px;
        .dashboard__table{
            width: 100%;
            thead tr th{
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                text-align: justify;
                background:  #F2F2F2;
                padding: 1rem;
            }
           
        }
    }
}
}