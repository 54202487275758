.sidebar__container{
    box-sizing: border-box;
    width: 254px;
    background: #ffff;
    color: $color-black;
    height: 100%;
    position: fixed;
    left: -260px;
    top: 0;
    z-index: 9;
    overflow-x: hidden;
    overflow-y: auto;
    transition: left 0.5s ease-out;
    &.sidebar-visible {
        left: 0;
        padding: 1rem;
      }
     &.sidebar-hidden {
        left: -260px;
      
}

    .logo__section{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .close__button{
            display: block;
            @include desktop{
                display: none;
            }
            

        }
        
    }
    
    .menu__container{
        margin: 3rem 0rem;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        
        .upgrade__wrapper{
            margin-top: 4rem;
            position: fixed;
            bottom: 15px;
            left: 15px;
            display: flex;
            align-items: center;
            padding: 14px 18px;
            gap: 20px;
            color: #ffff;
            background: $purple;
            border-radius: 10px;
            transition: left 0.5s ease-out;
            &.hide__button{
                left: -260px;
               
            }
            
            a{
                &:hover{
                    color:white;
                    text-decoration: none;
                }
            }
        }
        .nav__menu{
            .menu__item{
                
                .menu_link{
                display: flex;
                gap: 26px;
                padding: 16px 8px;
                align-items: center;
                transition: 0.2s ease-in all;
                font-size: 16px;
                &.active{
                    color: $purple;
                    font-weight: 600;
                    
    
                }
                    &:hover{
                        color: $purple;
                        text-decoration: none;
                       margin-left: 8px;
                svg {
                    path{
                        stroke: $purple;
                        strokeWidth: 2;
                    }
                    
                }
                    }
                }
            }

            .sub__menu{
                padding: .1rem 1.5rem .1rem 3.3rem;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
               
                .sub__menu__item{
                    
                    .sub__menu__link{
                        &:hover{
                            color:$purple
                        }
                        &.active_sub_menu{
                            color:$purple
                        }
                    }
                    padding-bottom: 1rem;
                }
            }
           
           
        }
    }

    
}