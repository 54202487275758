.email__input {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.25); 
  
    label {
      font-weight: bold;
      padding: 5px 0px;
    }
  
    input {
      border: none;
      padding: 5px;
      outline: none;
    }
  }
  .message__box {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.25); 
   
    textarea {
      border: none;
      padding: 5px;
      outline: none;
    }
  }