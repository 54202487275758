$primary-color: #e68403;

.home-slider{
    .slick-prev {
        &::before {
            display: none;
        }
    }
    .slick-next {
        &::before {
            display: none;
        }
    }
    .custom-indicator {
        bottom: -4.4rem;
        padding: 2px;
        
        @include desktop{
            bottom: -4.4rem;
            padding: 2px;
        }
        li {
            height: 50px;
            border: $primary-color;
        }
        li.slick-active {
            div {
                border-radius: 50%;
                height: 50px;
                background-color: $primary-color;
            }
        }
    }
    .image-container {
        box-sizing: border-box;
        max-height:100% ;
        background: transparent;
    
        @include desktop{
            height: 23.813rem;
            background: transparent;
            box-sizing: border-box;
        }
        img {
            width: 100%;
            object-fit: cover;
        }
    }
    
}