.bread__crumb{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding: 1rem 0rem;
    color:$color-black;
    a{
        text-decoration: none;
        &:hover{
            text-decoration: none;
            color:$purple;
        }
    }
}