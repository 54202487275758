.top__nav{
    border-bottom: 1px solid #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .4rem;
   
    .hamburger__menu{
        padding: .6rem 1rem;
        cursor: pointer;
        @include desktop{
            padding: .6rem 1.5rem;
        }
        
    }
    .nav__menu__wrapper{
        display: flex; 
        
        @include mobile{
            flex: 1;
            justify-content: space-between;
        }
    }
    .menu__item{
        display: flex;
        align-items: center;
        border-right: 1px solid #F2F2F2;
        padding: .1rem;
        padding-right: .4rem;
        justify-content: space-between;
        font-weight: 500;
        gap: .2rem;
        font-size: 14px;
        line-height: 24px;
        color: $purple;
        flex-wrap: wrap;
        @include desktop{
            display: flex;
            align-items: center;
            border-right: 1px solid #F2F2F2;
            padding: .6rem;
            font-weight: 500;
            gap: .5rem;
            font-size: 16px;
            line-height: 24px;
            color: $purple;
        }
        &:hover{
            color: $purple;
            text-decoration: none;
        }
    }
    .user__container{
        position: relative;
        padding: .6rem 1rem .6rem .6rem;
        display: flex;
        align-items: center;
        gap: .6rem;
        @include desktop{
            position: relative;
            padding: .6rem 1.5rem;
            display: flex;
            align-items: center;
            gap: .6rem;
        }
        .user__icon{
            background: rgba(1, 111, 185, 0.15);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            .store__logo{
                width: 34px;
                height: 34px;
                border-radius: 50%;
                object-fit: cover;
            }
            .logo__default{
                padding: .5rem;
            }
        }
        .user__info{
            .merchant__name{
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
            }
            .merchant__username{
                font-weight: 400;
                font-size: 10px;
                line-height: 10px;
                color: #4950578C;
            }
            
        }
        .dropdown__arrow{
            cursor: pointer;
        }

    }
    .dropdown__content{
        position: absolute;
        right: 1.5rem;
        padding: 1rem 1.4rem;
        width: 207px;
        border: 1px solid rgba(24, 25, 31, 0.15);
        box-shadow: 2px 6px 15px rgba(94, 96, 206, 0.09);
        border-radius: 10px;
        background: #ffff;
        z-index: 3;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.33s, opacity 0.33s ease-in-out;
        &.dropdown__show{
            visibility: visible;
            opacity: 1;
            transition-delay: 0s;
        }
    
        
        .dropdown__item{
            display: flex;
            gap: 1rem;
            align-items: center;
            padding: 1.4rem auto;
            margin: 1rem auto;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: $color-black;
            &:last-child{
                color:red;
            }
            a{
                &:hover{
                    text-decoration: none;
                    color: inherit;
                }
            }
        }
    }
}