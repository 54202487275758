.complete__setup{
    
    .success__icon{
        width: 100%;
        height: 100%;
        max-width: 130px;
        max-height: 130%;
        object-fit: contain;
        margin-bottom: .4rem;
    }
    .header__section__wrapper{
        margin: 2rem 0rem;
        display: flex;
        justify-content: space-between;
        .user__info{
            .title{
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color:#9598A4;
                span{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: #495057;
                }
            }
            .description{
                margin-top: .2rem;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #9598A4
            }
        }
    }
    .content__wrapper{
        margin: 4rem auto;
        width: 100%;
        //padding-bottom: 20rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include desktop{
        margin: 4rem auto;
        width: 40%;
        //padding-bottom: 20rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        }

        
        .text__wrapper{
            color: #21252A;
            .title{
                text-align: center;
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
            }
            .description{
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.4px;
            }
        }
        
    }
    .button__wrapper{
        display: flex;
        justify-content: space-around;
        margin: 10px auto 14rem auto;
        gap:.5rem;
        flex-wrap: wrap;
        @include desktop{
            gap: 0;
        }
        
    }

    
}
.share__wrapper{
    //padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
 
    .share__item{
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        height: 73px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .5rem;
        @include desktop{
            width: 460px;
        }
        .icon__wrapper{
            display: flex;
            gap: 1rem;
            .share__icon{
                width: 36px;
                height: 36px;
                border-radius: 50%;
                background: #5E60CE26;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .share__text{
                .title{
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #21252A;
                }
                .description{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    color: #21252A;
                }
            }
        }
        .share__button{
            display: flex;
            gap: 1rem;
            color:$purple;
            cursor: pointer;
        }
    }
}