.avatar{
    width: 48px;
    height: 48px;
    border-radius: 50%;

    &-xs{
        width: 24px;
        height: 24px;
    }
    &-md {
        width: 32px;
        height: 32px;
    }

    &-image{
        width: inherit;
        height: inherit;
        object-fit: cover;
        border-radius: 50%;
    }
}