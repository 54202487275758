$primary-color:#FE8F01;
.buyer-cart{
    font-family: 'Inter';
    padding-bottom: 5rem;

    .product-header{
        padding: .6rem 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: none;

        @include desktop{
        padding: .6rem 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex !important;
        justify-content: space-between;
       
        }
        

        @include desktop{
            display: flex;
            justify-content: space-between;
        padding: .6rem 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: block;
        border-bottom: 1px solid gainsboro;
        }
}
    .buyer-cart-item{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 1rem 0px;
        border-bottom: 1px solid gainsboro;
        &:last-child{
            border-bottom: none;
        }

        @include desktop{
            padding: 1rem 0px;
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            border-bottom: 1px solid gainsboro;
            &:last-child{
                border-bottom: none;
            }
            
        }
        
        .total-wrapper{
            display: flex;
            justify-content: flex-end;
            width: 50px;
            @include desktop{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 200px;
            }
           
            .total{
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                align-self: center;
                display: none;
                @include desktop{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    align-self: center;
                    display: block;
                }
            }
           
        }

    }

    .product-wrapper{
        height: max-content;
        border-radius: 5px;
        margin-bottom: 0px;
        border: 1px solid rgba(24, 25, 31, 0.15);

        .cart__header{
            margin-top: 1rem;
            font-weight: 800;
            font-size: 16px;
            line-height: 19px;
            color: #18191F;
            text-align: center;
        }
        .empty__cart{
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #18191F;
            padding: .6rem;
        }
        
        .product-image{
            display: flex;
            gap: 10px;
            width:240px;
        
            @include desktop{
                display: flex;
                gap: 10px;
                width:250px;
            }
        }

        .quantity-wrapper{
            border-radius: 4px;
            display: flex;
            gap: 0px;
            align-items: center;
            margin: 10px auto;
            width:80px;
            order: 3;

            @include desktop{
            border-radius: 4px;
            display: flex;
            gap: 0px;
            align-items: center;
            width:80px;
            order: 0;
            margin: auto;
           
            }
            

            .button-group{
                display: flex;
                flex-direction: column;
                button{
                    padding:0px 10px;
                    margin-left:-4px;
            
                   
                }
                .add-button{
                    border-top-right-radius: 4px;
                    height: 23px;
                    border-bottom: 0px;
                   
                }
                .reduce-button{
                    border-bottom-right-radius: 4px;
                    height: 23px;
                }
            }
            button{
                padding:10px 15px;
                border:1px solid #cecfd7;
               
                
            }
            .quantity-button{
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
              
            }
            
            
        }
       
        .image-container{
            width: 71px;
            height: 80px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .product-details{
            
            .name{
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: $primary-color
            }
            
        }
        .table-header{
            display: none;
            @include desktop{
                display: block;
            }
            th{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
            }
        }
    }
    td{
        vertical-align: middle;
    }
    .checkout-wrapper{
        border-radius: 5px;
        height: max-content;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        .button-primary{
            margin-top: 2rem;
            margin-bottom: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 40px;
        width: 100%;
        gap: 10px;
        background: $primary-color;
        border: 1px solid $primary-color;
        border-radius: 10px;
        color: white;
        &:disabled{
            background: rgba(254, 143, 1, 0.35);
            border-color: rgba(254, 143, 1, 0.35);
            user-select: none;
        }
        }
        hr{
            height: 0px;
            margin-bottom: 0px;
        }
        .fees{
            display: flex;
            justify-content: space-between;
            align-items:baseline;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            padding: 1.6rem 0px .3rem 0px;
            border-bottom: 1px solid rgba(24, 25, 31, 0.15);
        }
    }
    .continue-shopping{
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        cursor: pointer;
    }

   .courier__image{
    height: 30px;
    width: 30px;
    object-fit: cover;
   }
   .courier__row{
    background: #F2F2F2;
    padding: 0.6rem;
    margin: 10px;
    td{
        padding: 8px;
    }
   }
}