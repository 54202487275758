$color_1: #FFFFFF;
$font-family_1: 'Inter';
 
.product-card {
    box-sizing: border-box;
    width: 100%;
    max-width: 10.2rem;
    padding: 10px;
    text-align: center;
    height: auto;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 4px 15px rgba(242, 242, 242, 0.09);
    &:hover{
        color: inherit;
    }

	@include desktop{
        box-sizing: border-box;
        max-width: 272px;
        width: 100%;
        height: auto;
        background: #FFFFFF;
        border: 1px solid #F2F2F2;
        box-shadow: 0px 4px 15px rgba(242, 242, 242, 0.09);
        border-radius: 4px;
        padding: 6px 10px;
        text-align: left;
    }
    
    .card-content{
        height: 160px;
      
        @include desktop{
        height: auto;
     
        }
    } 
       
	.title {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        text-align: center;

		@include desktop{
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: left;
        }
	}
	.product__price {
        font-weight: 800;
        font-size: 15px;
        line-height: 18px;

		@include desktop{
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 19px;
            margin-top: 5px;
        }
	}

	.btn-addto-cart {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: 139px;
        height: auto;
        padding: 8px 16px;
		gap: 5px;
		background: #FE8F01;
		border: 1px solid #FE8F01;
		border-radius: 10px;
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: $color_1;

		@include desktop{
        padding: 8px 16px;
		gap: 5px;
		width: 100%;
		max-width: 151px;
		height: 40px;
		background: #FE8F01;
		border: 1px solid #FE8F01;
		border-radius: 10px;
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		display: flex;
		color: $color_1;
        }
	}
    .product-image-container{
        position: relative;
        width: 100%;
        max-width: 10.2rem;
        height: 102px;
        padding: 0px;
        
        @include desktop {
            position: relative;
            width: 100%;
            height: 293px;
            max-width: 258px;
            max-height: 293px;
            padding: 0px;
        }
        img{
           
            width: 100%;
            height: 100%;
            max-width: 140px;
            object-fit: cover;
            padding: 0px;
           
            
            @include desktop{
                width: 100%;
                height: 100%;
                max-width: 258px;
                max-height: 293px;
                object-fit: cover;
                position: relative;
                top: 0;
                left: 0;
                bottom: 0px;
               
                &:hover{
                    .image-overlay{
                        background-color: red;
                        display: block;
                        align-items: center;
                        justify-content: center;
                    }
                }
                
            }
        }
    }

    .image-overlay{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0px;
        height: 40px;
        width: 100%;
        max-width: 258px;
        background-color: #FE8F01;
        opacity: .8; 
        border-radius: 4px;
    }
   
    .store-name{
        text-align: center;
        opacity: 1;
        font-size: .9em;
        color: white;

    }
   
}

