.pricing{
    .plan__container{
        margin-top: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .plans{
        border: 1px solid #D5DAE1;
        display: flex;
        width: max-content;
        border-radius: 8px;
        .plan__type{
            cursor: pointer;
            padding: 8px 10px;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #D5DAE1;
            flex-wrap: wrap;
            font-size: 14px;
            @include desktop{
                padding: 12px 14px;
                color: #18191F;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }
            &:last-child{
                border: none;
            }
            &.active__plan{
                background-color: $purple;
                color: white;
                &:first-child{
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
                &:last-child{
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }
        
        }
        
    }
    .user__select {
        margin: auto;
        margin: 30px auto;
        width: max-content;
        select{
            margin: auto;
            border-radius: 24px;
            width: 148px;
            padding: 10px 20px;
            cursor: pointer;
            background-position: 80% center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            // background: rgba(93, 95, 204, 0.07);
            height: 40px;
            color: #464646;
           
        }
    }
}