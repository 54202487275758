$color_1: #fff;

.searchBar{
    .input {
        outline: none;
        background: #FFFFFF;
        border: 1px solid rgba(24, 25, 31, 0.5);
    }
    .search_wrap {
        .search_box {
            position: relative;
            max-width: 500px;
            height: 100%;
            height: 42px;
            
            .input {
                width: 100%;
                height: 100%;
                padding: 10px 15px;
                border-radius: 10px;
                font-size: 18px;
            }
            .btn {
                position: absolute;
                top: 0;
                right: 0;
                width: 60px;
                height: 100%;
                background: #FE8F01;
                z-index: 1;
                cursor: pointer;
                &:hover {
                    background: #e68403;
                }
            }
        }
    }
    .search_wrap.search_wrap_6 {
        .search_box {
            .input {
                border-radius: 10px;
                padding-right: 145px;
                color:#18191F;
                font-size: 14px;
            
            }
            .btn {
                width: 72px;
                height: 36px;
                top: 3px;
                right: 3px;
              
                cursor: pointer;
                color: $color_1;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                cursor: pointer;
            }
        }
    }
    
    &::-webkit-input-placeholder {
        font-style: italic;
        font-size: 15px;
    }
    &:-moz-placeholder {
        font-style: italic;
        font-size: 15px;
    }
    &::-moz-placeholder {
        font-style: italic;
        font-size: 15px;
    }
    &:-ms-input-placeholder {
        font-style: italic;
        font-size: 15px;
    }
    
}