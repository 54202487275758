.customSelectContainer {
    position: relative;
    width: 200px;
  }
  
  .customSelect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    position: relative;
    &.disabled {
        background-color: #f1f1f1;
        cursor: not-allowed;
        border-color: #ddd;
        color: #aaa;
    
        .arrow {
          filter: grayscale(100%);
        }
      }
  }
  
  .arrow {
    width: 16px;
    height: 16px;
    background-image: url(/assets/images/svg/down-arrow-new.svg);
    background-repeat: no-repeat;
    transition: transform 0.3s ease;
  }
  
  .arrow.open {
    transform: rotate(180deg);
  }
  
  .options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    z-index: 1;
  }
  
  .option {
    padding: 10px;
    cursor: pointer;
  
    &:hover {
      background-color: #f1f1f1;
    }
  }
  